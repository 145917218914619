export const GTM_ID: string | undefined = process.env['NX_GTM_ID'];

export const GTMPageView = (rest: object) => {
    window['dataLayer']?.push({
        event: 'page_view',
        dimension1: 'online',
        ...rest,
    });
};

interface GTMEventParams {
    eventName: string;
    rest?: object;
}

export const GTMEvent = (props: GTMEventParams) => {
    const { eventName, ...rest } = props;
    window['dataLayer']?.push({
        event: eventName,
        dimension1: 'online',
        ...rest,
    });
};
