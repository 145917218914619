import { BannerNavMain } from '../banner-nav-main/banner-nav-main';
import Footer from '../footer/footer';
import { Transition } from '@headlessui/react';
import { slugify } from '@moar/utils';
import Layout from '../layout/layout';
import { NavFixedMobile } from '../nav-fixed-mobile/nav-fixed-mobile';
import NavMainFullDesktop from '../nav-main-full-desktop/nav-main-full-desktop';
import NavMainFullMobile from '../nav-main-full-mobile/nav-main-full-mobile';
import { useEffect, useRef, useState } from 'react';
import { PageHead } from '../page-head/page-head';
import BannerOffline from '../banner-offline/banner-offline';
import { MyTimelinePageProps } from '@moar/types';
import { useMyTimelineTitle } from '../context/myTimelineTitle';
import { IconArrow, IconClose, IconDownload, IconStar, LogoMoarColor } from '@moar/svg';
import useMyTimelineItems from '../context/myTimelineItems';
import Link from 'next/link';
import ModalClearMyTimeline from '../modal-clear-my-timeline/modal-clear-my-timeline';
import { useIsOnline } from '../context/online';
import Bugsnag from '@bugsnag/js';
import { editImageUrlDimensions } from '@moar/utils';

const CHARACTER_LIMIT = 30;

export function PageMyTimeline(props: MyTimelinePageProps) {
    const componentRef = useRef(null);
    const [isCreatingPdf, setIsCreatingPdf] = useState(false);
    const [isError, setIsError] = useState(false);
    const isOnline = useIsOnline();
    const {
        timelineTitle,
        preview,
        offlineAlertBanner,
        sections,
        aboutPageTitle,
        howToPageTitle,
        glossaryPageTitle,
        glossaryItemsCount,
    } = props;
    const [title, setTitle] = useMyTimelineTitle();
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const { items, removeItem, clearItems } = useMyTimelineItems();
    const hasItems = items && items.length > 0;

    useEffect(() => {
        setIsReady(true);
        // scroll to top when page loads
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }, []);

    const handlePdfCreation = async () => {
        if (isCreatingPdf) return;

        setIsError(false);
        setIsCreatingPdf(true);
        // get html of items from page and send it to the pdf creation function
        const toPrint = `${document.getElementById('timeline-for-print')?.innerHTML}`;

        // console.log(toPrint);

        // body is undefined locally
        const options = {
            method: 'POST',
            responseType: 'blob',
            body: toPrint,
        };
        try {
            const pdfGenUrl = `${process.env['NX_SITE_URL']}/.netlify/functions/pdfGenerator`;
            // console.log(pdfGenUrl);

            const response = await fetch(pdfGenUrl, options);
            // console.log({ response });

            if (response.ok) {
                const blob = await response.blob();
                // console.log(blob);

                // button to download avoids popup blockers
                const pdf = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
                setPdfUrl(pdf);
            } else {
                setIsError(true);
                Bugsnag.notify(response.statusText);
                throw response.statusText;
            }
        } catch (err) {
            console.log(err);
            setIsError(true);
        } finally {
            setIsCreatingPdf(false);
            // console.log('done');
        }
    };

    return (
        <Layout preview={preview}>
            <PageHead defaultTitle={timelineTitle} title={`My Timeline`} />
            <BannerOffline offlineAlertBanner={offlineAlertBanner} />
            <BannerNavMain preview={preview} title={timelineTitle} hideForPrint={true} />
            <NavMainFullDesktop
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossaryItemsCount}
            />
            <NavMainFullMobile
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossaryItemsCount}
            />

            <Transition
                show={isReady}
                appear={true}
                enter={`transition-opacity duration-500`}
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave={`transition-opacity duration-500`}
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="bg-midnight print:hidden">
                    <div
                        className={`grid grid-cols-12 px-6 pt-24 lg:container md:pb-12 md:pt-28 md:px-10 lg:px-0 ${
                            hasItems ? 'pb-5' : 'pb-10'
                        }`}
                    >
                        <div className="relative col-span-12 lg:col-span-8 lg:col-start-3">
                            <div className="mb-2">
                                <h1 className="font-serif text-5xl text-white md:text-7xl">My Timeline</h1>
                            </div>
                            <div className="block space-y-8 md:space-y-0 md:flex">
                                <div className="relative w-full pr-6">
                                    <input
                                        value={title}
                                        placeholder={'Name Your Timeline'}
                                        name="my_timeline_name"
                                        onInput={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            setTitle(e.target.value);
                                        }}
                                        maxLength={CHARACTER_LIMIT}
                                        type="text"
                                        className="w-full px-0 pt-3 pb-2 font-sans text-base leading-5 text-white border-b border-white rounded-none placeholder:text-white bg-midnight"
                                    />
                                    <span className="absolute left-0 ml-0 text-xs text-white -bottom-6">
                                        {title ? title.length : 0} characters (max {CHARACTER_LIMIT})
                                    </span>
                                </div>
                                {isOnline && hasItems && !pdfUrl && (
                                    <div className="flex">
                                        <button
                                            onClick={() => handlePdfCreation()}
                                            className="w-full min-w-0 px-8 btn btn--primary md:w-auto md:min-w-[200px]"
                                        >
                                            {isCreatingPdf ? 'Creating ...' : 'Create PDF'}
                                        </button>
                                    </div>
                                )}
                                {isOnline && pdfUrl && (
                                    <div className="flex space-x-6">
                                        <a
                                            href={pdfUrl}
                                            download={`${slugify(title ? title : 'My Timeline')}.pdf`}
                                            target="_blank"
                                            onClick={() => {
                                                setTimeout(() => {
                                                    setPdfUrl(null);
                                                }, 2000);
                                            }}
                                            className="w-full min-w-0 px-8 btn btn--primary md:w-auto md:min-w-[200px]"
                                            rel="noreferrer"
                                        >
                                            <div className="flex justify-center">
                                                <span className="mr-2">
                                                    <IconDownload />
                                                </span>
                                                Download PDF
                                            </div>
                                        </a>
                                    </div>
                                )}
                            </div>
                            {!isOnline && hasItems && (
                                <div className="mt-8 rounded-sm text-yellow">
                                    <div className="italic">
                                        Note: You must be online to create a PDF of your timeline.
                                    </div>
                                </div>
                            )}

                            {isError && (
                                <div className="p-2 mt-4 border rounded-sm md:mt-8 border-red text-red bg-ivory-dark">
                                    <div className="font-bold">
                                        There has been an error creating the PDF. Please try again.{' '}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {isConfirmOpen && (
                    <ModalClearMyTimeline
                        isOpen={isConfirmOpen}
                        onConfirm={() => {
                            setIsConfirmOpen(false);
                            clearItems();
                        }}
                        onClose={() => setIsConfirmOpen(false)}
                    />
                )}
                <div className="bg-ivory min-h-page print:hidden" ref={componentRef}>
                    <div className="grid grid-cols-12 px-6 pt-5 pb-20 lg:container md:pt-8 md:px-10 lg:px-0">
                        <div className="col-span-12 lg:col-span-8 lg:col-start-3">
                            {!items ||
                                (items.length === 0 && (
                                    <div className="relative space-y-3">
                                        <p>
                                            You haven’t started a timeline yet. Select the star icon
                                            <span className="mx-2 w-[14px] h-[16px] inline-block">
                                                <IconStar color={'midnight'} />
                                            </span>
                                            next to an object to begin adding to your timeline. Once your timeline is
                                            complete, you can download it as a PDF.
                                        </p>
                                        <p>
                                            <Link href="/sections">
                                                <a className="flex items-center link link--dark">
                                                    Get Started{' '}
                                                    <span className="ml-2">
                                                        <IconArrow direction="right" color="blue" />
                                                    </span>
                                                </a>
                                            </Link>
                                        </p>
                                    </div>
                                ))}
                            {items && items.length > 0 && (
                                <div className="mb-5 md:mb-8">
                                    <button className="flex items-center group" onClick={() => setIsConfirmOpen(true)}>
                                        <span className={`w-[9px] h-[9px] md:w-[11px] md:h-[11px]`}>
                                            <IconClose color="blue" />
                                        </span>
                                        <span className="mt-[2px] md:mt-1 ml-2 text-xs font-bold text-blue hover:text-midnight md:text-md">
                                            Clear All
                                        </span>
                                    </button>
                                </div>
                            )}
                            <div className="mb-6 space-y-6 break-after-avoid-page">
                                {items?.map((item) => (
                                    <div key={`my-timeline-drawer-${item.id}`} className="">
                                        <div className="flex items-center">
                                            <div className="my-timeline-thumb-big w-[60px] md:w-[170px]">
                                                <div className="my-timeline-thumb-big--frame">
                                                    <div className="my-timeline-thumb-big--helper"></div>
                                                    <Link href={`/objects/${item.slug}`}>
                                                        <a>
                                                            <img src={item.image?.url} alt={item.title} />
                                                        </a>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="w-full ml-6 md:ml-8">
                                                <div className="flex flex-col items-start md:flex-row md:justify-between md:items-center">
                                                    <div>
                                                        <p className="mb-0 text-base md:text-base-plus md:mb-1">
                                                            {item.date}
                                                        </p>
                                                        <h2 className="font-serif text-base md:text-2xl">
                                                            {item.title}
                                                        </h2>
                                                    </div>
                                                    <div>
                                                        <button
                                                            className="flex items-center mt-1 group md:mt-0"
                                                            onClick={() => removeItem(item)}
                                                        >
                                                            <span className="w-[8px] h-[8px] md:w-[11px] md:h-[11px]">
                                                                <IconClose color="blue" />
                                                            </span>
                                                            <span className="mt-[2px] ml-2 text-xs font-bold text-blue hover:text-midnight md:text-sm ">
                                                                Remove
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* For PDF */}
                <div className="hidden print:block" id="timeline-for-print">
                    <div id="print-header" className="mt-6 mb-6">
                        <div className="flex items-center">
                            <LogoMoarColor />
                            <h1 className="ml-8 font-serif text-4xl">{timelineTitle}</h1>
                        </div>
                    </div>
                    <h2 className="mt-6 font-serif text-black text-7xl">{title ? title : 'My Timeline'}</h2>
                    <div className="grid grid-cols-12 px-6 pt-5 pb-20 lg:container md:pt-8 md:px-10 lg:px-0 print:px-0">
                        <div className="col-span-12 lg:col-span-8 lg:col-start-3">
                            <div className="mb-6 space-y-2 break-after-avoid-page">
                                {items?.map((item, index) => {
                                    const imageUrl = editImageUrlDimensions(item.image?.url, 200);
                                    return (
                                        <>
                                            <div key={`my-timeline-print-${item.id}`} className="break-inside-avoid">
                                                <div className="flex items-center">
                                                    <div className="my-timeline-thumb-big w-[60px] md:w-[170px] print:w-[130px] print:h-[130px] ">
                                                        <div className="my-timeline-thumb-big--frame">
                                                            <div className="my-timeline-thumb-big--helper"></div>
                                                            <img src={imageUrl} alt={item.title} />
                                                            {/* <img
                                                        src={`https://moar-staging.imgix.net/e28d0132-95e4-4125-aec7-281c2d554423/standard-cookie-boards.png?fit=min&q=80&rect=0%2C0%2C4800%2C3300&fm=webp&auto=compress&w=300`}
                                                    /> */}
                                                        </div>
                                                    </div>
                                                    <div className="w-full ml-6 md:ml-8">
                                                        <div className="flex flex-col items-start md:flex-row md:justify-between md:items-center">
                                                            <div>
                                                                <p className="mb-0 text-base md:text-base-plus md:mb-1 print:text-lg">
                                                                    {item.date}
                                                                </p>
                                                                <h5 className="font-serif text-base md:text-2xl print:text-xl">
                                                                    {item.title}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* For page break on the second page - prevents item spanning pages and weird spacing */}
                                            {index < 10 && (index - 5) % 7 === 0 && (
                                                <div key={`my-timeline-print-${index}`} style={{ height: '4px' }}>
                                                    &nbsp;
                                                </div>
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
            <NavFixedMobile />
            <Footer />
        </Layout>
    );
}
