import {
    BannerNavMain,
    BannerSearchState,
    Layout,
    NavFixedMobile,
    Section,
    useDataSearch,
    useSearchState,
    NavMainFullDesktop,
    NavMainFullMobile,
    BannerOffline,
    PageHead,
    ModalCache,
} from '../../index';
import { useEffect, useRef, useState } from 'react';
import { PageSectionsIndexProps } from '@moar/types';
import Footer from '../footer/footer';
import useScrollDirection from '../hooks/use-scroll-direction/use-scroll-direction';
import ButtonBackToTop from '../button-back-to-top/button-back-to-top';
import { useInView } from 'react-cool-inview';
import TimelineNavVertical from '../timeline-nav-vertical/timeline-nav-vertical';
import ModalSafariImages from '../modal-safari-images/modal-safari-images';
import { useMainNavDisplay } from '../context/mainNavDisplay';
import { useRouter } from 'next/router';

// If online, the next/image is used to fade in and give it priority
// If offline, show image saved for offline
export function PageSections(props: PageSectionsIndexProps) {
    const {
        sections,
        preview,
        timelineTitle,
        offlineAlertBanner,
        seo,
        aboutPageTitle,
        howToPageTitle,
        glossaryPageTitle,
        glossaryItemsCount,
    } = props;
    const { sectionSeoTitle, sectionSeoDescription, sectionSeoImage, sectionSeoKeyword } = seo;
    const { isActive, query, filters } = useSearchState();
    const router = useRouter();
    const { asPath } = router;
    const [isReady, setIsReady] = useState<boolean>(false);

    const { setIsOpen: setNavIsOpen } = useMainNavDisplay();
    const { sectionsFiltered } = useDataSearch();
    const scrollDirection = useScrollDirection();
    const { observe: observeButtonContainer, inView: inViewButton } = useInView({
        rootMargin: `-300px 0px`,
    });
    const scrollToRef = useRef<HTMLHeadingElement>(null);

    // scrolling for search
    useEffect(() => {
        if (!window || !isActive) return;
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
    }, [isActive, query, filters]);

    useEffect(() => {
        const hash = asPath.split('#')[1];

        let timeout = 0;
        if (hash === 'nav-open') {
            setNavIsOpen(true);
            timeout = 500;
        }

        // scroll to section or object on page load
        // and scroll back to object and section on back button from browser and to timeline from detail
        if (hash) {
            setTimeout(() => {
                const el = document.getElementById(hash);

                if (el) {
                    el.scrollIntoView();
                }
            }, 100);
        }

        const readyTimeout = setTimeout(() => {
            setIsReady(true);
        }, timeout);

        return function cleanup() {
            if (!readyTimeout) return;
            clearTimeout(readyTimeout);
        };
    }, []);

    const sectionsToDisplay = isActive ? sectionsFiltered : sections;

    if (!isActive && (!sections || sectionsToDisplay.length === 0)) return null;

    return (
        <Layout preview={preview}>
            <PageHead
                title={sectionSeoTitle}
                defaultTitle={timelineTitle}
                description={sectionSeoDescription}
                image={sectionSeoImage}
                keywords={sectionSeoKeyword}
            />

            <BannerNavMain persistNav={false} disableSearch={false} preview={preview} title={timelineTitle} />
            <NavMainFullDesktop
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossaryItemsCount}
            />
            <NavMainFullMobile
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossaryItemsCount}
            />
            <BannerSearchState />
            <div ref={scrollToRef} className="relative">
                {!isReady && <div className="w-screen h-screen bg-blue"></div>}
                {isReady && (
                    <>
                        <div className={`relative ${isActive ? '' : 'md:mt-[60px]'} `} ref={observeButtonContainer}>
                            {/* No results for search */}
                            {sectionsToDisplay.length === 0 && (
                                <div
                                    className="min-h-page"
                                    style={{
                                        backgroundColor: sections[0].color.replace(', 1)', ', 0.75)'),
                                    }}
                                >
                                    <div className="py-10 pl-6 md:pl-20">
                                        {/* <H4 textColor={`text-xl ${sections[0].colorIsTint ? 'text-white' : 'text-black'}`}>
                                    No results for this search.
                                </H4> */}
                                    </div>
                                </div>
                            )}

                            {sectionsToDisplay.map((section, i) => (
                                <Section
                                    key={`section-${section.slug}`}
                                    section={section}
                                    onlyOneSectionToDisplay={sectionsToDisplay.length === 1}
                                />
                            ))}
                        </div>
                        <TimelineNavVertical sections={sections} />
                        {sectionsToDisplay.length > 0 && (
                            <ButtonBackToTop
                                bgColor={sectionsToDisplay[sectionsToDisplay.length - 1].color.replace(
                                    ', 1)',
                                    ', 0.75'
                                )}
                                isVisible={inViewButton && scrollDirection === 'down'}
                            />
                        )}
                    </>
                )}
            </div>

            <BannerOffline offlineAlertBanner={offlineAlertBanner} />
            <NavFixedMobile disableSearch={false} />
            <Footer padForBottomNav={true} />
        </Layout>
    );
}
