import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../error-fallback/error-fallback';
import Bugsnag from '@bugsnag/js';

interface ErrorHandlerProps {
    children: ReactNode;
}

// is it necessary to log bug to bugsnag here too?
// because it's already doing it in the error wrapper around the app
export function ErrorBoundaryApp(props: ErrorHandlerProps) {
    const { children } = props;

    const myErrorHandler = (error: Error, info: { componentStack: string }) => {
        // Do something with the error
        // E.g. log to an error logging client here
        // Bugsnag.notify(error);
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
            {children}
        </ErrorBoundary>
    );
}

export default ErrorBoundaryApp;
