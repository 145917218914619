/* eslint-disable-next-line */
export interface IconPauseProps {}

export function IconPause(props: IconPauseProps) {
    return (
        <span className="w-5 h-5">
            <svg
                aria-hidden={true}
                className="w-5 h-5"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className={`fill-white md:betterhover:group-hover:fill-yellow`}
                    d="M4.375 13.5L6.625 13.5C6.83211 13.5 7 13.3321 7 13.125L7 4.875C7 4.66789 6.83211 4.5 6.625 4.5L4.375 4.5C4.16789 4.5 4 4.66789 4 4.875L4 13.125C4 13.3321 4.16789 13.5 4.375 13.5Z"
                    fill="white"
                />
                <path
                    className={`fill-white md:betterhover:group-hover:fill-yellow`}
                    d="M10.375 13.5L12.625 13.5C12.8321 13.5 13 13.3321 13 13.125L13 4.875C13 4.66789 12.8321 4.5 12.625 4.5L10.375 4.5C10.1679 4.5 10 4.66789 10 4.875L10 13.125C10 13.3321 10.1679 13.5 10.375 13.5Z"
                />
            </svg>
        </span>
    );
}

export default IconPause;
