import { IconArrowCaret } from '@moar/svg';
import Link from 'next/link';
import { Banner, ParagraphSmall, useContextData } from '../../index';
import usePwaSupported from '../hooks/use-pwa-supported/use-pwa-supported';

/* eslint-disable-next-line */
export interface BannerPwaNoSupportProps {}

const msg =
    'Your browser does not appear to support a Progressive Web App, but you can still use the timeline without offline access.';

export function BannerPwaNoSupport(props: BannerPwaNoSupportProps) {
    const { isSupported } = usePwaSupported();
    const data = useContextData();

    if (isSupported) return null;
    if (!data) return null;

    const { alert } = data.data;

    if (!alert.unsupportedPromptText) return null;

    return (
        <div className="">
            <Banner>
                <div className="flex items-center">
                    <div className="flex flex-col items-start ml-0 space-x-0 md:items-center md:space-x-3 md:ml-2 md:flex-row">
                        <div>
                            <ParagraphSmall className="mb-3 mr-1 text-left text-black md:mb-0">
                                {alert.unsupportedPromptText}{' '}
                                <span className="inline md:hidden">
                                    Supported mobile browsers: Safari, Chrome (Android) &amp; Firefox (Android)
                                </span>
                                <span className="hidden md:inline">Supported desktop browsers: Chrome &amp; Edge</span>
                            </ParagraphSmall>
                        </div>
                        <div className="flex justify-start space-x-4">
                            <div className="block md:ml-2 md:inline-block">
                                <Link href="/how-to">
                                    <a className="btn btn--outline-dark">Learn more</a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Banner>
        </div>
    );
}

export default BannerPwaNoSupport;
