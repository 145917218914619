import { BannerNavMain } from '../banner-nav-main/banner-nav-main';
import Footer from '../footer/footer';
import { Transition } from '@headlessui/react';

import Layout from '../layout/layout';
import { NavFixedMobile } from '../nav-fixed-mobile/nav-fixed-mobile';
import NavMainFullDesktop from '../nav-main-full-desktop/nav-main-full-desktop';
import NavMainFullMobile from '../nav-main-full-mobile/nav-main-full-mobile';
import RelatedResources from '../related-resources/related-resources';
import { useEffect, useState } from 'react';
import { PageHead } from '../page-head/page-head';
import { TypePageAboutProps } from '@moar/types';
import BannerOffline from '../banner-offline/banner-offline';

export function PageAbout(props: TypePageAboutProps) {
    const {
        page,
        timelineTitle,
        preview,
        sections,
        offlineAlertBanner,
        relatedResources,
        howToPageTitle,
        glossaryPageTitle,
        glossaryItemsCount,
    } = props;
    const [isReady, setIsReady] = useState(false);
    useEffect(() => {
        setIsReady(true);
    }, []);

    const { aboutBody, aboutSeoTitle, aboutSeoImage, aboutSeoDescription, aboutImages, aboutPageTitle } = page;

    return (
        <Layout preview={preview}>
            <PageHead
                defaultTitle={timelineTitle}
                title={aboutSeoTitle}
                image={aboutSeoImage}
                description={aboutSeoDescription}
            />
            <BannerOffline offlineAlertBanner={offlineAlertBanner} />
            <BannerNavMain preview={preview} title={timelineTitle} />
            <NavMainFullDesktop
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossaryItemsCount}
            />
            <NavMainFullMobile
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossaryItemsCount}
            />
            <Transition
                show={isReady}
                appear={true}
                enter={`transition-opacity duration-500`}
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave={`transition-opacity duration-500`}
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="bg-ivory min-h-page">
                    <div className="grid grid-cols-12 px-6 pt-24 pb-20 lg:container md:pt-28 md:px-10 lg:px-0">
                        <div className="col-span-12 lg:col-span-8 lg:col-start-3">
                            <div className="mb-8">
                                <h1 className="pb-4 mb-8 font-serif text-5xl border-b border-black md:text-7xl">
                                    {aboutPageTitle}
                                </h1>
                                {!!aboutBody && (
                                    <div className="prose-page" dangerouslySetInnerHTML={{ __html: aboutBody }}></div>
                                )}
                            </div>

                            {aboutImages.length > 0 && (
                                <>
                                    <h2 className="mb-8 font-serif text-4xl text-center">Made Possible By</h2>
                                    <div>
                                        <ul className="flex flex-wrap items-center justify-center">
                                            {aboutImages.map((image) => (
                                                <li className="mx-4 my-8" key={`about-image-${image.url}`}>
                                                    {/* 388x136px */}
                                                    <div className="w-[194px] h-[68px]">
                                                        <img src={image.url} alt={image.image_alt_text} />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <RelatedResources relatedResources={relatedResources} />
            </Transition>
            <NavFixedMobile />
            <Footer padForBottomNav={true} />
        </Layout>
    );
}

export default PageAbout;
