import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import { hideAll } from 'tippy.js';

import { IconArrowCaret } from '@moar/svg';
import { TypeTimelineObjectImage } from '@moar/types';
import CarouselSlide from './carousel-slide/carousel-slide';
import AnimateFadeIn from '../animate-fade-in/animate-fade-in';
import { useContextModalZoom } from '../context/modalZoom';

export interface ObjectCarouselProps {
    images: TypeTimelineObjectImage[];
    slug: string;
    color: string;
    mode: 'light' | 'dark';
    clickZoom: () => void;
}

interface StyledThumbScreenProps {
    readonly sectionColor?: string;
}

const StyledThumbScreen = styled.span<StyledThumbScreenProps>`
    &:hover {
        background-color: ${(props) => props.sectionColor || 'blue'};
    }
`;

// design 894x632
// images 3:2 or 3:4?
// 3:2 - 922x614 - 1840*
// 3:4 - 922x691 - 1844x1382
export function ObjectCarousel(props: ObjectCarouselProps) {
    const { images, slug, color, mode = 'light', clickZoom } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const { setImage } = useContextModalZoom();

    useEffect(() => {
        setImage(images[0]);
    }, []);

    if (!images) return null;

    const renderPrev = (clickHandler: () => void, hasPrev: boolean, label: string) => {
        return (
            hasPrev && (
                <button
                    // type="button"
                    className={`group carousel__control-btn carousel__control-btn--prev ${
                        mode === 'dark' ? 'carousel__control-btn--light' : 'carousel__control-btn--dark'
                    }`}
                    aria-label="prev image"
                    onClick={clickHandler}
                    title={label}
                >
                    <IconArrowCaret direction="left" color={mode === 'dark' ? 'blue' : 'yellow'} />
                </button>
            )
        );
    };

    const renderNext = (clickHandler: () => void, hasNext: boolean, label: string) =>
        hasNext && (
            <button
                type="button"
                className={`group carousel__control-btn carousel__control-btn--next ${
                    mode === 'dark' ? 'carousel__control-btn--light' : 'carousel__control-btn--dark'
                }`}
                aria-label="next image"
                onClick={clickHandler}
                title={label}
            >
                <IconArrowCaret color={mode === 'dark' ? 'blue' : 'yellow'} />
            </button>
        );

    // Uses deprecated ReactChild so just force any
    const customRenderThumb = (children: any): any => {
        if (!children) return null;
        return children.map((item: any) => {
            return (
                <div key={`thumb-${item.props.slide.url}`} className="thumb__frame">
                    <StyledThumbScreen sectionColor={color} className="thumb__frame-screen"></StyledThumbScreen>
                    <span className="thumb__frame-helper"></span>
                    <img alt={item.props.slide.image_alt_text} src={item.props.slide.url} />
                </div>
            );
        });
    };

    return (
        <div className="relative carousel__objects">
            <AnimateFadeIn key={`carousel-fade-in-${slug}`}>
                <Carousel
                    key={`carousel-${slug}`}
                    showIndicators={false}
                    showStatus={false}
                    showThumbs={images.length > 1}
                    renderThumbs={(children) => customRenderThumb(children)}
                    thumbWidth={40}
                    renderArrowPrev={renderPrev}
                    infiniteLoop={false}
                    renderArrowNext={renderNext}
                    // animationHandler="fade"
                    onChange={(index) => {
                        setActiveIndex(index);
                        setImage(images[index]);
                        hideAll();
                    }}
                >
                    {images.map((image, index) => (
                        <CarouselSlide
                            key={`slide-${image.url}`}
                            accountForThumbs={images.length > 1}
                            bgColor={color}
                            clickZoom={clickZoom}
                            isActive={index === activeIndex}
                            mode={mode}
                            slide={image}
                        />
                    ))}
                </Carousel>
            </AnimateFadeIn>
        </div>
    );
}

export default ObjectCarousel;
