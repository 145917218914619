import classNames from 'classnames';

/* eslint-disable-next-line */
export interface IconVideoProps {
    color?: 'black' | 'white' | 'yellow' | 'blue';
    size?: 'sm' | 'base';
}

export function IconVideo(props: IconVideoProps) {
    const { color = 'yellow', size = 'base' } = props;

    const fillClass = classNames({
        'fill-yellow hover:fill-yellow-dark group-hover:fill-yellow-dark': color === 'yellow',
        'fill-white hover:fill-white group-hover:fill-yellow': color === 'white',
        'fill-black hover:fill-blue group-hover:fill-blue': color === 'black',
        'fill-blue hover:fill-blue group-hover:fill-black': color === 'blue',
    });

    const sizeClass = classNames({
        'w-3': size === 'sm',
        'w-3.5': size === 'base',
    });

    return (
        <svg
            aria-hidden={true}
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`${fillClass} transition-all ${sizeClass}`}
        >
            <path d="M11.2,2.2H2c-1.1,0-1.9,0.9-1.9,1.9v7.7c0,1.1,0.9,1.9,1.9,1.9h9.2c1.1,0,1.9-0.9,1.9-1.9V4.1C13.1,3.1,12.3,2.2,11.2,2.2z M9.1,8.3L5,11.3c-0.3,0.2-0.8,0-0.8-0.4V5c0-0.4,0.4-0.6,0.8-0.4l4.1,2.9C9.4,7.7,9.4,8.1,9.1,8.3z"/>
            <path d="M14,6.1l3.1-2.2c0.3-0.2,0.8,0,0.8,0.4v7.3c0,0.4-0.5,0.6-0.8,0.4L14,9.8c0,0-0.1-0.1-0.1-0.1l0-3.4C14,6.2,14,6.1,14,6.1z"
            />
        </svg>
    );
}

export default IconVideo;
