/* eslint-disable-next-line */
export interface IconFacebookProps {}

export function IconFacebook(props: IconFacebookProps) {
    return (
        <svg
            aria-hidden={true}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="fill-white group-hover:fill-yellow"
                d="M16 7.99997C15.9998 6.47118 15.5615 4.97447 14.737 3.68703C13.9126 2.3996 12.7365 1.37534 11.3481 0.735528C9.95962 0.0957155 8.4169 -0.132862 6.90257 0.0768555C5.38823 0.286573 3.9657 0.925804 2.80337 1.91887C1.64104 2.91194 0.787596 4.21726 0.344069 5.68029C-0.099457 7.14333 -0.114491 8.70282 0.300748 10.1741C0.715987 11.6454 1.54411 12.967 2.68708 13.9823C3.83004 14.9976 5.23999 15.6641 6.75 15.903V10.3125H4.719V7.99997H6.75V6.23747C6.75 4.23247 7.9445 3.12497 9.7715 3.12497C10.3715 3.13329 10.9701 3.18543 11.5625 3.28097V5.24997H10.5535C10.3816 5.22713 10.2067 5.24328 10.0418 5.29722C9.87698 5.35115 9.72638 5.44149 9.60119 5.56154C9.476 5.6816 9.37943 5.82828 9.31864 5.99073C9.25784 6.15317 9.23439 6.32722 9.25 6.49997V7.99997H11.469L11.114 10.3125H9.25V15.903C11.1319 15.6053 12.8458 14.6457 14.0832 13.1969C15.3206 11.7481 16.0003 9.90526 16 7.99997Z"
            />
        </svg>
    );
}

export default IconFacebook;
