/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, ReactNode, useContext, useState } from 'react';

type TypeContextSearch = {
    query: string;
    setQuery: (query: string) => void;
    filters: string[];
    setFilters: (filters: string[]) => void;
};

const initialValues = {
    query: '',
    setQuery: () => {},
    filters: [],
    setFilters: () => {},
};

export const ContextSearch = createContext<TypeContextSearch>(initialValues);

export function ProviderSearch({ children }: { children: ReactNode }) {
    // https://stackoverflow.com/questions/52423842/what-is-not-assignable-to-parameter-of-type-never-error-in-typescript
    const defaultFilters: string[] = [];
    const [query, setQuery] = useState('');
    const [filters, setFilters] = useState(defaultFilters);

    return <ContextSearch.Provider value={{ query, setQuery, filters, setFilters }}>{children}</ContextSearch.Provider>;
}

function useContextSearch() {
    return useContext(ContextSearch);
}

// /sections?query=test&tags=[test,another]
// {
//     tags: ['Abby Test', 'Object'],
//     query: 'test'
// }
export const updateSearchUrl = (props: { tags?: string[]; query?: string }) => {
    const { tags, query } = props;
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (tags) {
        urlSearchParams.set('tags', tags.join(','));
    }
    if (typeof query !== 'undefined') {
        urlSearchParams.set('query', query);
    }

    const newParams = Object.fromEntries(urlSearchParams.entries());
    const params = [];
    for (const [key, value] of Object.entries(newParams)) {
        params.push(`${key}=${encodeURIComponent(value)}`);
    }

    window.history.pushState(
        {},
        '',
        params.length > 0 ? `${window.location.pathname}?${params.join('&')}` : window.location.pathname
    );
};

export function useSearchState() {
    const { filters, setFilters, query, setQuery: setQueryValue } = useContextSearch();

    const reset = () => {
        setQuery('');
        setFilters([]);
        updateSearchUrl({ tags: [], query: '' });
    };

    const addFilter = (filter: string) => {
        if (filters.includes(filter)) return;
        const newFilters = [...filters, filter];

        setFilters(newFilters);
        updateSearchUrl({ tags: newFilters });
    };

    const replaceFilters = (newFilters: string[]) => {
        setFilters(newFilters);
        updateSearchUrl({ tags: newFilters });
    };

    const removeFilter = (filter: string) => {
        const newFilters = filters.filter((f) => f !== filter);
        setFilters(newFilters);
        updateSearchUrl({ tags: newFilters });
    };

    const setQuery = (newQuery: string) => {
        setQueryValue(newQuery);
        updateSearchUrl({ query: newQuery });
    };

    return {
        query,
        setQuery,
        filters,
        addFilter,
        replaceFilters,
        removeFilter,
        isActive: query !== '' || filters.length > 0,
        reset,
    };
}
