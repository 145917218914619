import {
    ErrorFallback,
    ProviderData,
    ProviderElementPositions,
    ProviderIsOnline,
    ProviderMainNavDisplay,
    ProviderModalCacheDisplay,
    ProviderMyTimelineDisplay,
    ProviderMyTimelineItems,
    ProviderMyTimelineTitle,
    ProviderSearch,
    ProviderSearchDisplay,
    ProviderVerticalNavSectionOverlap,
    PwaContext,
} from '@moar/ui';
import { AppProps } from 'next/app';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './styles.css';
import { useRouter } from 'next/router';
import React, { createContext, useEffect, useState } from 'react';
import Script from 'next/script';
import { GTMPageView, GTM_ID } from '@moar/utils';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
    onError: (event) => {
        if (process.env.NODE_ENV === 'production') {
            return true;
        }
        return false;
    },
    apiKey: process.env['NX_BUGSNAG_API'],
    plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function CustomApp({ Component, pageProps }: AppProps) {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            let displayMode = 'browser';
            if (window.navigator['standalone']) {
                displayMode = 'standalone';
            }
            if (window.matchMedia('(display-mode: standalone)').matches) {
                displayMode = 'standalone';
            }

            GTMPageView({
                page: url,
                displayMode,
            });
        };
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {GTM_ID && (
                <>
                    {/* Google Tag Manager - Global base code */}
                    <Script
                        id="google-tag-manager"
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');
`,
                        }}
                    />
                </>
            )}
            <main>
                <PwaContext.Provider value={false}>
                    <ProviderIsOnline>
                        <ProviderData>
                            <ProviderSearch>
                                <ProviderMainNavDisplay>
                                    <ProviderSearchDisplay>
                                        <ProviderMyTimelineDisplay>
                                            <ProviderMyTimelineTitle>
                                                <ProviderMyTimelineItems>
                                                    <ProviderElementPositions>
                                                        <ProviderVerticalNavSectionOverlap>
                                                            <ProviderModalCacheDisplay>
                                                                <ToastContainer
                                                                    position="bottom-left"
                                                                    toastClassName={`toast-container--dark`}
                                                                    autoClose={3000}
                                                                    className={`toast-container`}
                                                                    hideProgressBar={true}
                                                                    transition={Slide}
                                                                    limit={1}
                                                                    role="alert"
                                                                />
                                                                <Component {...pageProps} />
                                                            </ProviderModalCacheDisplay>
                                                        </ProviderVerticalNavSectionOverlap>
                                                    </ProviderElementPositions>
                                                </ProviderMyTimelineItems>
                                            </ProviderMyTimelineTitle>
                                        </ProviderMyTimelineDisplay>
                                    </ProviderSearchDisplay>
                                </ProviderMainNavDisplay>
                            </ProviderSearch>
                        </ProviderData>
                    </ProviderIsOnline>
                </PwaContext.Provider>
            </main>
        </ErrorBoundary>
    );
}

export default CustomApp;
