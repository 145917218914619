import IconClose from './icon-close';

/* eslint-disable-next-line */
export interface IconCloseCircleBigProps {}

export function IconCloseCircleBig(props: IconCloseCircleBigProps) {
    return (
        <div className="flex items-center justify-center bg-white rounded-full w-9 h-9 group-hover:bg-ivory drop-shadow-icon">
            <span className=" w-[12px] h-[12px]">
                <IconClose />
            </span>
        </div>
    );
}

export default IconCloseCircleBig;
