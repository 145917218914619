import { useState, useCallback, useEffect } from 'react';
import * as React from 'react';
import * as UAParser from 'ua-parser-js';

type IUseUserAgentReturn = Omit<UAParser.IResult, 'ua'>;

export function useUserAgent() {
    const [state, setState] = useState<IUseUserAgentReturn | null>(null);

    useEffect(() => {
        if (!window) return;
        const uastring = window.navigator.userAgent;
        let didRun = true;

        try {
            const uaParser = new UAParser.UAParser();
            uaParser.setUA(uastring);

            const payload = {
                os: uaParser.getOS(),
                browser: uaParser.getBrowser(),
                cpu: uaParser.getCPU(),
                device: uaParser.getDevice(),
                engine: uaParser.getEngine(),
            };
            if (didRun) {
                setState(payload);
            }
        } catch (err) {
            if (didRun) {
                setState(null);
            }
        }

        return () => {
            didRun = false;
        };
    }, []);

    return state;
}

export default useUserAgent;
