/* eslint-disable-next-line */
export interface IconPlayProps {}

export function IconPlay(props: IconPlayProps) {
    return (
        <span className="w-5 h-5">
            <svg
                aria-hidden={true}
                className="w-5 h-5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.7504 7.49972C11.7504 7.31633 11.6609 7.1442 11.5102 7.03901L5.88486 3.10129C5.71385 2.98091 5.48884 2.96741 5.30264 3.06304C5.11644 3.16036 5 3.35218 5 3.562V11.438C5 11.6478 5.11644 11.8402 5.30264 11.937C5.48884 12.0326 5.71329 12.0191 5.88486 11.8987L11.5102 7.96099C11.6609 7.85636 11.7504 7.68423 11.7504 7.50084C11.7504 7.50028 11.7504 7.50028 11.7504 7.49972C11.7504 7.50028 11.7504 7.50028 11.7504 7.49972Z"
                    className={`fill-white md:betterhover:group-hover:fill-yellow`}
                />
            </svg>
        </span>
    );
}

export default IconPlay;
