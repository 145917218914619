import { useContextData } from '../../../index';
import { useState, useEffect } from 'react';
import { TypeSections } from '@moar/types';
import useResponsive from '../use-responsive/use-responsive';
import { getImagesToCache, GTMEvent } from '@moar/utils';

interface IBeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;
    prompt(): Promise<void>;
}

declare global {
    interface Window {
        workbox: any;
    }
}

export function useAddToHomescreenPrompt(): [IBeforeInstallPromptEvent | null, () => void] {
    const [prompt, setPrompt] = useState<IBeforeInstallPromptEvent | null>(null);
    const data = useContextData();
    const { isMobileDesign } = useResponsive();

    const promptToInstall = async () => {
        if (prompt) {
            prompt.prompt();
            const { outcome } = await prompt.userChoice;
            // Optionally, send analytics event with outcome of user choice
            console.log(`User response to the install prompt: ${outcome}`);
            // We've used the prompt, and can't use it again, throw it away
            if (outcome === 'accepted') {
                GTMEvent({ eventName: 'app_install' });
            }
            return outcome;
        }
        return Promise.reject(new Error('Tried installing before browser sent "beforeinstallprompt" event'));
    };

    useEffect(() => {
        console.log('%c Add listner beforeinstallprompt', 'color: orange');

        const ready = (e: IBeforeInstallPromptEvent) => {
            console.log('%c Set prompt!!!!', 'background: #222; color: #bada55');
            e.preventDefault();

            setPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', ready as any);

        return () => {
            console.log('removed ');

            window.removeEventListener('beforeinstallprompt', ready as any);
        };
    }, []);

    useEffect(() => {
        const onInstall = () => {
            console.log('onInstall');

            if (!data) return;

            // Hide the app-provided install promotion
            // Clear the deferredPrompt so it can be garbage collected
            setPrompt(null);
            // Optionally, send analytics event to indicate successful install
            console.log('PWA was installed', isMobileDesign);
            const urls = getImagesToCache({ data, isMobile: isMobileDesign });

            window.workbox.messageSW({
                command: 'log',
                message: 'pwa install',
                action: 'PWA_INSTALL',
                payload: urls,
            });
        };
        // Not supported by Safari!
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/appinstalled_event
        window.addEventListener('appinstalled', onInstall);
        return () => {
            window.removeEventListener('appinstalled', onInstall);
        };
    }, [data, isMobileDesign]);

    return [prompt, promptToInstall];
}

export default useAddToHomescreenPrompt;
