import { IconArrow } from '@moar/svg';
import classNames from 'classnames';
import Link from 'next/link';

/* eslint-disable-next-line */
export interface ObjectNavProps {
    prevSlug?: string | undefined;
    nextSlug?: string | undefined;
    colorIsTint: boolean;
    color?: string;
}

interface LinkInnardsPrevProps {
    colorIsTint: boolean;
    linkClasses: string;
}

const LinkInnardsPrev = (props: LinkInnardsPrevProps) => {
    const { colorIsTint, linkClasses } = props;
    return (
        <div className={`w-full flex py-10 items-center font-serif text-2xl lg:w-24 ${linkClasses} transition-all`}>
            <div className="flex items-center justify-center w-full md:justify-start">
                <span>
                    <IconArrow size="sm" color={colorIsTint ? 'black' : 'white'} />
                </span>
                <span className="ml-3">
                    Previous <br /> Object
                </span>
            </div>
        </div>
    );
};

const LinkInnardsNext = (props: LinkInnardsPrevProps) => {
    const { colorIsTint, linkClasses } = props;
    return (
        <div
            className={`w-full flex py-6 items-center font-serif  lg:w-20 text-2xl text-left ${linkClasses} transition-all`}
        >
            <div className="flex items-center justify-center w-full md:justify-end">
                <span className="mr-3">
                    Next
                    <br /> Object
                </span>
                <span>
                    <IconArrow size="sm" direction="right" color={colorIsTint ? 'black' : 'white'} />
                </span>
            </div>
        </div>
    );
};

export function ObjectNav(props: ObjectNavProps) {
    const { prevSlug, nextSlug, colorIsTint, color } = props;

    const linkClasses = classNames({
        'text-white md:group-hover:text-yellow md:group-active:text-yellow-md': !colorIsTint,
        'text-blue md:group-hover:text-midnight md:group-active:text-blue-dark': colorIsTint,
    });

    return (
        <>
            <div
                className="z-20 flex items-center justify-center w-1/2 md:justify-start md:pl-10 lg:py-0 lg:w-auto lg:absolute lg:left-6 lg:top-36"
                style={{ backgroundColor: color }}
            >
                {prevSlug && (
                    <Link href={`/objects/${prevSlug}`}>
                        <a className={`block w-full group`} aria-label={`Go to prev object`} role="link">
                            <LinkInnardsPrev linkClasses={linkClasses} colorIsTint={colorIsTint} />
                        </a>
                    </Link>
                )}
                {!prevSlug && (
                    <a role="link" aria-disabled="true" className={`opacity-50 hover:text-white pointer-events-none`}>
                        <LinkInnardsPrev linkClasses={linkClasses} colorIsTint={colorIsTint} />
                    </a>
                )}
            </div>
            <div
                className="z-20 flex items-center justify-center w-1/2 border-l md:justify-end md:pr-10 lg:py-0 border-ivory lg:border-0 lg:w-auto lg:absolute lg:right-6 lg:top-36"
                style={{ backgroundColor: color }}
            >
                {nextSlug && (
                    <Link href={`/objects/${nextSlug}`} aria-label={`Go to next object`}>
                        <a className={`block w-full group`}>
                            <LinkInnardsNext linkClasses={linkClasses} colorIsTint={colorIsTint} />
                        </a>
                    </Link>
                )}
                {!nextSlug && (
                    <a role="link" aria-disabled="true" className={`opacity-50 hover:text-white pointer-events-none`}>
                        <LinkInnardsNext linkClasses={linkClasses} colorIsTint={colorIsTint} />
                    </a>
                )}
            </div>
        </>
    );
}

export default ObjectNav;
