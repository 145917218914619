import { TypeTimelineGlossaryItem } from '@moar/types';
import { slugify } from '@moar/utils';

interface GlossaryByLetter {
    letter: string;
    items: TypeTimelineGlossaryItem[];
}

interface GlossaryProps {
    itemsByLetter: GlossaryByLetter[];
}

function Glossary({ itemsByLetter }: GlossaryProps) {
    return (
        <div>
            {itemsByLetter.map((group) => (
                <div
                    id={`${group.letter.toLowerCase()}`}
                    className="scroll-mt-20"
                    key={`glossary-group-${group.letter}`}
                >
                    <h2 className={`mb-5 text-3xl font-serif md:text-6xl print:mb-1 break-after-avoid`}>
                        {group.letter}
                    </h2>
                    <ul>
                        {group.items.map((item) => (
                            <li
                                className="mb-5 scroll-mt-20 print:mb-1"
                                id={`${slugify(item.title)}`}
                                key={`glossary-term-${slugify(item.title)}`}
                            >
                                <h3 className="mb-1 font-bold break-after-avoid">{item.title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}

export default Glossary;
