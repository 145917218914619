import classNames from 'classnames';

/* eslint-disable-next-line */
export interface IconArrowProps {
    color?: 'black' | 'white' | 'yellow' | 'blue';
    direction?: 'left' | 'right';
    size?: 'sm' | 'base';
}

export function IconArrow(props: IconArrowProps) {
    const { color = 'yellow', direction = 'left', size = 'base' } = props;

    const rotateClass = classNames({
        'rotate-180': direction === 'right',
    });

    const fillClass = classNames({
        'fill-yellow hover:fill-yellow-dark group-hover:fill-yellow-dark': color === 'yellow',
        'fill-white hover:fill-white group-hover:fill-yellow': color === 'white',
        'fill-black hover:fill-blue group-hover:fill-blue': color === 'black',
        'fill-blue hover:fill-blue group-hover:fill-black': color === 'blue',
    });

    const sizeClass = classNames({
        'w-3': size === 'sm',
        'w-3.5': size === 'base',
    });

    return (
        <svg
            aria-hidden={true}
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`${fillClass} ${rotateClass} transition-all ${sizeClass}`}
        >
            <path d="M6.79185 0.41793L0.266894 4.22523C-0.0889645 4.43304 -0.0889645 4.94772 0.266894 5.15552L6.79185 8.96068C7.06372 9.11895 7.33991 8.7631 7.11918 8.53914L4.06881 5.44624C4.00259 5.37948 3.94714 5.30573 3.90246 5.2282H13.4616C13.7594 5.2282 14 4.98702 14 4.68984C14 4.39266 13.7594 4.15148 13.4616 4.15148H3.90246C3.94714 4.07395 4.00259 4.00074 4.06827 3.93452L7.11918 0.839467C7.33991 0.615508 7.06319 0.259651 6.79185 0.41793Z" />
        </svg>
    );
}

export default IconArrow;
