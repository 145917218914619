import { TypePageIntroProps } from '@moar/types';
import useResponsive from '../hooks/use-responsive/use-responsive';
import { Intro } from '../intro/intro';
import Layout from '../layout/layout';
import { PageHead } from '../page-head/page-head';

export function PageIntro(props: TypePageIntroProps) {
    const { preview, timelineTitle, seo, intro } = props;
    const { isMobileDesign } = useResponsive();

    const { timelineSeoTitle, timelineSeoDescription, timelineSeoImage, timelineSeoKeywords } = seo;
    return (
        <Layout preview={preview}>
            <PageHead
                title={timelineSeoTitle}
                defaultTitle={timelineTitle}
                description={timelineSeoDescription}
                image={timelineSeoImage}
                keywords={timelineSeoKeywords}
                preloadImage={isMobileDesign ? intro.backgroundImageMobile?.url : intro.backgroundImageDesktop?.url}
            />

            <Intro {...props} />
        </Layout>
    );
}

export default PageIntro;
