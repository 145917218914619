import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

export const ContextElementPositionsDisplay = createContext<[boolean, (setFirstSectionIntroInView: boolean) => void]>([
    false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
]);

export function ProviderElementPositions({ children }: { children: ReactNode }) {
    const [firstSectionIntroInView, setFirstSectionIntroInView] = useState(false);
    return (
        <ContextElementPositionsDisplay.Provider value={[firstSectionIntroInView, setFirstSectionIntroInView]}>
            {children}
        </ContextElementPositionsDisplay.Provider>
    );
}

function useContextElementPositions() {
    return useContext(ContextElementPositionsDisplay);
}

export const useElementPositions = () => {
    const [firstSectionIntroInView, setFirstSectionIntroInView] = useContextElementPositions();

    return { firstSectionIntroInView, setFirstSectionIntroInView };
};
