/* eslint-disable-next-line */
export interface IconCloseCircleProps {
    mode?: 'light' | 'dark';
}

export function IconCloseCircle(props: IconCloseCircleProps) {
    const { mode = 'light' } = props;

    const color = mode === 'light' ? '#1A1A1A' : '#FFFFFF';

    return (
        <svg
            aria-hidden={true}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11 5L5 11" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 5L11 11" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default IconCloseCircle;
