/* eslint-disable-next-line */
export interface IconMenuProps {}

export function IconMenu(props: IconMenuProps) {
    return (
        <div className="relative flex items-center justify-center bg-white rounded-full w-9 h-9 group-hover:bg-ivory-dark drop-shadow-icon">
            <div className="">
                <svg
                    aria-hidden={true}
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <ellipse cx="6.22087" cy="1.27911" rx="1.2791" ry="1.2791" fill="#172535" />
                    <ellipse cx="6.22087" cy="5.81347" rx="1.2791" ry="1.2791" fill="#172535" />
                    <circle cx="6.22087" cy="10.3478" r="1.2791" fill="#172535" />
                </svg>
            </div>
        </div>
    );
}

export default IconMenu;
