export const slugify = (text: string) => {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};

export function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    console.log({ i });

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function editImageUrlDimensions(url: string|undefined, resizeTo: number) {
    if (typeof url === 'undefined') {
        return url;
    }

    // This method should resize an image while maintaining the aspect ratio.
    // The `resizeTo` parameter is the max height or width of the image
    // (whichever is currently larger).
    const widthRegex = new RegExp(/([?&])w=(\d+)/);
    const heightRegex = new RegExp(/([?&])h=(\d+)/);

    const width = parseInt((widthRegex.exec(url)?.at(2) ?? '0'));
    const height = parseInt((heightRegex.exec(url)?.at(2) ?? '0'));

    return height > width
        ? url.replace(heightRegex, `$1h=${resizeTo}`).replace(widthRegex, `$1w=${Math.round(width/(height/resizeTo))}`)
        : url.replace(widthRegex, `$1w=${resizeTo}`).replace(heightRegex, `$1h=${Math.round(height/(width/resizeTo))}`)
}
