import { useState, useEffect } from 'react';
import useUserAgent from '../use-user-agent/use-user-agent';
import * as UAParser from 'ua-parser-js';

// PWAs are supported on:
// Chrome (Desktop)
// Edge (Desktop)
// Safari (Mobile)
// Chrome (Mobile Android)
// Firefox (Mobile Android)

// PWAs are not supported on:
// Safari (Desktop)
// Chrome (Mobile Apple)
// Firefox (Desktop)
// Yandex (Desktop)

type IUseUserAgentReturn = Omit<UAParser.IResult, 'ua'>;

// Browser detection is obviously terrible, feature detection would be better/
// but there is no way to detect if PWA installation is supported via feature detection
const getIsSupported = (userAgent: IUseUserAgentReturn) => {
    if (
        getIsDesktopChrome(userAgent) ||
        getIsDesktopEdge(userAgent) ||
        getIsSupportedSafari(userAgent) ||
        getIsAndroidChrome(userAgent) ||
        getIsAndroidFirefox(userAgent) ||
        getIsSupportedAndroid(userAgent) ||
        getIsSupportedDesktop(userAgent)
    ) {
        return true;
    }
    return false;
};

const getIsSupportedDesktop = (userAgent: IUseUserAgentReturn) => {
    if (getIsDesktopChrome(userAgent) || getIsDesktopEdge(userAgent)) {
        return true;
    }
    return false;
};

const getIsDesktopChrome = (userAgent: IUseUserAgentReturn) => {
    if (userAgent['device'].type !== 'mobile' && userAgent['browser'].name === 'Chrome') {
        return true;
    }
    return false;
};

const getIsDesktopEdge = (userAgent: IUseUserAgentReturn) => {
    if (userAgent['device'].type !== 'mobile' && userAgent['browser'].name === 'Edge') {
        return true;
    }
    return false;
};

const getIsSupportedSafari = (userAgent: IUseUserAgentReturn) => {
    if (userAgent['browser'].name === 'Mobile Safari') {
        return true;
    }
    if (userAgent['browser'].name === 'Safari' && window.navigator.maxTouchPoints > 1) {
        return true;
    }
    return false;
};

const getIsAndroidChrome = (userAgent: IUseUserAgentReturn) => {
    if (userAgent.os.name === 'Android' && userAgent['browser'].name === 'Chrome') {
        return true;
    }
    return false;
};

const getIsAndroidFirefox = (userAgent: IUseUserAgentReturn) => {
    if (userAgent.os.name === 'Android' && userAgent['browser'].name === 'Firefox') {
        return true;
    }
    return false;
};

const getIsSupportedAndroid = (userAgent: IUseUserAgentReturn) => {
    if (getIsAndroidChrome(userAgent) || getIsAndroidFirefox(userAgent)) {
        return true;
    }
    return false;
};

type UsePWASupportedProps = {
    isSupported: boolean;
    isSupportedDesktop: boolean;
    isSupportedSafari: boolean;
    isSupportedAndroid: boolean;
    isDesktopChrome: boolean;
    isDesktopEdge: boolean;
    isAndroidChrome: boolean;
    isAndroidFirefox: boolean;
};

export function usePwaSupported(): UsePWASupportedProps {
    const userAgent = useUserAgent();
    const [isSupported, setIsSupported] = useState<boolean>(false);
    const [isSupportedDesktop, setIsSupportedDesktop] = useState<boolean>(false);
    const [isSupportedSafari, setIsSupportedSafari] = useState<boolean>(false);
    const [isSupportedAndroid, setIsSupportedAndroid] = useState<boolean>(false);

    const [isDesktopChrome, setIsDesktopChrome] = useState<boolean>(false);
    const [isDesktopEdge, setIsDesktopEdge] = useState<boolean>(false);
    const [isAndroidChrome, setIsAndroidChrome] = useState<boolean>(false);
    const [isAndroidFirefox, setIsAndroidFirefox] = useState<boolean>(false);

    useEffect(() => {
        if (!userAgent) return;
        setIsSupported(getIsSupported(userAgent));
        setIsSupportedDesktop(getIsSupportedDesktop(userAgent));
        setIsSupportedSafari(getIsSupportedSafari(userAgent));
        setIsSupportedAndroid(getIsSupportedAndroid(userAgent));

        setIsDesktopChrome(getIsDesktopChrome(userAgent));
        setIsDesktopEdge(getIsDesktopEdge(userAgent));
        setIsAndroidChrome(getIsAndroidChrome(userAgent));
        setIsAndroidFirefox(getIsAndroidFirefox(userAgent));
    }, [userAgent]);

    return {
        isSupported,
        isSupportedDesktop,
        isSupportedSafari,
        isSupportedAndroid,
        isDesktopChrome,
        isDesktopEdge,
        isAndroidChrome,
        isAndroidFirefox,
    };
}

export default usePwaSupported;
