import classNames from 'classnames';

/* eslint-disable-next-line */
export interface IconCloseProps {
    color?: 'black' | 'yellow' | 'blue';
}

export function IconClose(props: IconCloseProps) {
    const { color = 'black' } = props;
    const colorClasses = classNames({
        'stroke-blue group-hover:stroke-midnight': color === 'blue',
        'stroke-midnight group-hover:stroke-blue': color === 'black',
        'stroke-yellow group-hover:stroke-yellow-dark': color === 'yellow',
    });

    return (
        <svg
            aria-hidden={true}
            className="w-full h-full"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path className={colorClasses} d="M0.5 0.5L9.5 9.5" strokeLinecap="round" strokeLinejoin="round" />
            <path className={colorClasses} d="M9.5 0.5L0.5 9.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default IconClose;
