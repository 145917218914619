import { IconStar } from '@moar/svg';
import { TypePersistedTimelineItem } from '@moar/types';
import { useMemo, useState } from 'react';
import useMyTimelineItems from '../context/myTimelineItems';
import ToastMyTimeline from '../toast-my-timeline/toast-my-timeline';
import { toast } from 'react-toastify';
import useResponsive from '../hooks/use-responsive/use-responsive';
import Tippy from '@tippyjs/react';

interface TooltipProps {
    isSelected?: boolean;
}
const Tooltip = (props: TooltipProps) => {
    const { isSelected = false } = props;
    const text = isSelected ? 'Remove from My Timeline' : 'Add to My Timeline';
    return <div className="w-auto h-auto p-2 text-white transition-all rounded-sm tooltip bg-midnight">{text}</div>;
};
export interface ButtonToggleMyTimelineProps {
    mode?: 'dark' | 'light';
    item: TypePersistedTimelineItem;
    justIcon?: boolean;
}

export function ButtonToggleMyTimeline(props: ButtonToggleMyTimelineProps) {
    const { mode = 'light', item, justIcon = false } = props;
    const [animateClass, setAnimateClass] = useState('');
    const { isMobile } = useResponsive();
    const { items, addItem, removeItem } = useMyTimelineItems();

    const isInMyTimeline = useMemo(() => {
        const ids = items?.map((item: TypePersistedTimelineItem) => item.id);
        return ids?.includes(item.id);
    }, [items, item.id]);

    const handleClick = () => {
        if (isInMyTimeline) {
            removeItem(item);
        } else {
            toast(({ closeToast, toastProps }) => <ToastMyTimeline closeToast={closeToast} toastProps={toastProps} />);
            addItem(item);
        }
        setAnimateClass('motion-safe:animate-spin');
        setTimeout(() => {
            setAnimateClass('');
        }, 250);
    };

    const displayJustIcon = justIcon || isMobile;
    const renderText = () => {
        if (displayJustIcon) return '';
        return isInMyTimeline ? 'Remove from My Timeline' : 'Add to My Timeline';
    };

    return (
        <div>
            <Tippy
                className={`${!displayJustIcon ? 'hidden' : ''}`}
                content={<Tooltip isSelected={isInMyTimeline} />}
                placement={'top'}
                hideOnClick={false}
            >
                <button
                    aria-label={`${isInMyTimeline ? 'Remove' : 'Add'} Object to My Timeline`}
                    onClick={handleClick}
                    className={`group relative h-6 ${displayJustIcon ? 'w-[14px]' : ''}`}
                >
                    <div className={`${animateClass} absolute left-0 bottom-[6px]`}>
                        <span className="w-[14px] h-[16px] flex">
                            <IconStar color={mode === 'light' ? 'blue' : 'yellow'} solid={isInMyTimeline} />
                        </span>
                    </div>

                    <span
                        className={`${
                            mode === 'dark'
                                ? 'text-white group-hover:text-yellow'
                                : 'text-blue group-hover:text-midnight'
                        } ml-5 text-sm font-bold  `}
                    >
                        {renderText()}
                    </span>
                </button>
            </Tippy>
        </div>
    );
}

export default ButtonToggleMyTimeline;
