import React from 'react';
import SlideOverSearch from '../slide-over-search/slide-over-search';
import { useIsOnline } from '../context/online';
import useClearCache from '../hooks/use-clear-cache/use-clear-cache';
import SlideOverMyTimeline from '../slide-over-my-timeline/slide-over-my-timeline';
import ModalCache from '../modal-cache/modal-cache';
import { useRouter } from 'next/router';

type LayoutProps = {
    children: JSX.Element | JSX.Element[];
    navMode?: 'dark' | 'light';
    preview: boolean;
};

export const Layout = (props: LayoutProps) => {
    const { children, preview = false } = props;
    const isOnline = useIsOnline();
    useClearCache({ preview });
    const router = useRouter();

    return (
        <div className={`${isOnline ? 'app-online' : 'app-offline'}`}>
            {children}
            <SlideOverSearch />
            <SlideOverMyTimeline />
            {router.pathname !== '/' && <ModalCache />}
        </div>
    );
};

export default Layout;
