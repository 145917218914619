/* eslint-disable-next-line */
export interface ErrorFallbackProps {}

export function ErrorFallback(props: ErrorFallbackProps) {
    return (
        <div className="relative w-screen h-screen bg-blue">
            <div className="flex items-center justify-center">
                <div className="mx-6">
                    <h2 className="mt-10 font-bold text-center text-white">An Error has Occured</h2>
                    <p className="mb-4 text-center text-white">We've logged the issue. Please refresh or contact us.</p>
                </div>
            </div>
        </div>
    );
}

export default ErrorFallback;
