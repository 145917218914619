import { useContextData } from '../../../index';
import { useState, useEffect } from 'react';
import { useSearchState } from '../../context/search';
import { TypeTimelineDate, TypeTimelineObject, TypeSection, TypeTag } from '@moar/types';

interface TypeFilterWithCount {
    label: string;
    count: number;
}

export interface UseDataSearch {
    count: number | undefined;
    filtersWithCounts: TypeFilterWithCount[];
    sectionsFiltered: TypeSection[];
}

type TypeTimelineItems = (TypeTimelineObject | TypeTimelineDate)[];

const getMatchesFilters = (currentFilters: string[], items: TypeTimelineItems): TypeTimelineItems => {
    if (currentFilters.length === 0) return items;
    const getItemHasCurrentFilters = (currentFilters: string[], tags?: TypeTag[]) => {
        const objects = tags?.filter((f) => currentFilters.includes(f.title));
        return objects && objects.length > 0;
    };
    return items.filter((item) => getItemHasCurrentFilters(currentFilters, 'tags' in item ? item['tags'] : undefined));
};

const getMatchesQuery = (items: TypeTimelineItems, query: string): TypeTimelineItems => {
    if (query === '') return items;
    const queryWords = query.toLowerCase().trim().split(' ');

    const matches = items.filter((item) => {
        let textToSearch = '';
        textToSearch += item.title ? item.title : '';
        textToSearch += item.date ? item.date : '';
        textToSearch += item.timeline_excerpt ? item.timeline_excerpt : '';
        textToSearch += item.description ? item.description : '';
        textToSearch += item.tombstone ? item.tombstone : '';
        textToSearch += textToSearch.toLowerCase().replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
        // match all words in the query, but in any order
        const matches = queryWords.filter((word) => {
            return textToSearch && textToSearch.indexOf(word) >= 0;
        });
        return textToSearch && matches.length === queryWords.length;
    });
    return matches;
};

export function useDataSearch(): UseDataSearch {
    const data = useContextData();
    const [count, setCount] = useState<number | undefined>();
    const [filtersWithCounts, setFiltersWithCounts] = useState<TypeFilterWithCount[]>([]);
    const [sectionsFiltered, setSectionsFiltered] = useState<TypeSection[]>([]);
    const { query, filters } = useSearchState();

    // update count
    useEffect(() => {
        if (!data) return;

        // update total count
        const items = data.data.timelineSections.data.map((section) => section.objects).flat();
        const matchesForFilters = getMatchesFilters(filters, items);
        const matches = getMatchesQuery(matchesForFilters, query);

        setCount(matches.length);

        // udpate filters with counts
        // - get all tags in filtered object
        // - get the unique ones
        // - match up unique tags with counts from all tags

        const allTags = matches.map((item) => ('tags' in item ? item['tags'] : undefined)).flat();
        const notEmptyTags = allTags.filter((tag) => typeof tag !== 'undefined') as TypeTag[];
        const uniqueTags = [...new Map(notEmptyTags.map((item) => [item['title'], item])).values()];

        //[{label: 'Arts & Symbolism', count: xx}]
        const counts = uniqueTags.map((tag: TypeTag) => {
            return { label: tag.title, count: allTags.filter((t) => t?.title === tag.title).length };
        });

        setFiltersWithCounts(counts);
    }, [data, query, filters]);

    // setDataFiltered
    useEffect(() => {
        if (!data) return;
        if (query === '' && filters.length === 0) {
            // null?
            setSectionsFiltered(data.data.timelineSections.data);
            return;
        }

        const matchesForFilters = data.data.timelineSections.data.map((section) => {
            const itemMatches = getMatchesFilters(filters, section.objects);
            if (itemMatches.length > 0) {
                return { ...section, objects: itemMatches };
            }
            return undefined;
        });
        const notEmptySections = matchesForFilters.filter((section) => typeof section !== 'undefined') as TypeSection[];
        const matchesForQuery = notEmptySections.map((section) => {
            const itemMatches = getMatchesQuery(section.objects, query);
            if (itemMatches.length > 0) {
                return { ...section, objects: itemMatches };
            }
            return undefined;
        });
        const sectionMatches = matchesForQuery.filter((section) => typeof section !== 'undefined') as TypeSection[];
        setSectionsFiltered(sectionMatches);
    }, [data, query, filters]);

    return { count, filtersWithCounts, sectionsFiltered };
}

export default useDataSearch;
