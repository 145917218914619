import { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { IconCloseBox } from '@moar/svg';
import useResponsive from '../hooks/use-responsive/use-responsive';
import { formatBytes, getImagesToCache } from '@moar/utils';
import { useContextData } from '../context/data';
import { useIsOnline } from '../context/online';
import { useContextModalCacheDisplay } from '../context/modalCacheDisplay';

/* eslint-disable-next-line */
export interface ModalCacheProps {}

export function ModalCache(props: ModalCacheProps) {
    const [isOpen, setIsOpen] = useContextModalCacheDisplay();
    const { isMobileDesign } = useResponsive();
    const isOnline = useIsOnline();
    const data = useContextData();
    const [cacheImagesState, setCacheImagesState] = useState('Check for Images');
    const [cacheResetState, setCacheResetState] = useState('Refresh Images');
    const [cacheImages, setCacheImages] = useState<null | []>(null);
    const [successImageMsg, setSuccessImageMsg] = useState<null | string>(null);

    useEffect(() => {
        // maybe happens in browser? Error in bugsnag as undefined
        if (!navigator.serviceWorker) return;

        const handleMessage = (event: any) => {
            if (event.data.type === 'RETURN_CACHE_IMAGES') {
                setCacheImages(event.data.payload);
                setCacheImagesState('');
            }
            if (event.data.type === 'CACHE_IMAGES_DONE') {
                setCacheResetState('Refresh Images');
                handleGetCacheImages();
                setSuccessImageMsg(`Done! Loaded: ${event.data.payload.count} Images`);
            }
        };
        // Listen to the response
        navigator.serviceWorker.addEventListener('message', handleMessage);

        return function cleanup() {
            navigator.serviceWorker.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        setSuccessImageMsg(null);

        if (isOpen) {
            handleGetCacheImages();
        }
    }, [isOpen]);

    const handleCacheImages = () => {
        if (!data) return;
        setSuccessImageMsg(null);
        setCacheResetState('Loading...');
        setCacheImagesState('Loading (this may take a while) ...');
        setCacheImages(null);
        const urls = getImagesToCache({ data, isMobile: isMobileDesign });

        window.workbox.messageSW({
            command: 'log',
            message: 'cache images',
            action: 'CACHE_IMAGES',
            payload: urls,
        });
    };

    const handleGetCacheImages = async () => {
        setCacheImagesState('Loading ...');
        window.workbox.messageSW({
            command: 'log',
            message: 'get cache images',
            action: 'GET_CACHE_IMAGES',
        });
    };

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <Dialog open={isOpen} onClose={() => onClose()} className="fixed inset-0 z-40">
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-75" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className="relative w-full px-8 py-8 overflow-y-scroll md:max-w-md bg-ivory">
                    <div className="absolute z-50 right-6 top-6">
                        <button onClick={() => onClose()}>
                            <IconCloseBox />
                            <span className="sr-only">Close Modal</span>
                        </button>
                    </div>
                    <Dialog.Title className="font-serif text-3xl transition-all md:text-6xl">App Settings</Dialog.Title>
                    <div className="my-6">
                        <h2 className="mb-2 font-sans text-base font-bold">Images for Offline Use</h2>
                        <p className="mb-2 text-sm">
                            We recommend connecting to wifi instead of using cellular data before refreshing the images.
                        </p>
                        <div className="mt-4">
                            <div className="flex items-center space-x-4">
                                {cacheImages && (
                                    <div className="mr-2">
                                        Images: {cacheImages.length}{' '}
                                        {/* {cacheImageSize !== null ? `${formatBytes(cacheImageSize)}` : ''} */}
                                    </div>
                                )}
                                <span className="font-serif font-bold animate-pulse">{cacheImagesState}</span>
                            </div>
                            <button className="mt-2 btn btn--primary" onClick={() => handleGetCacheImages()}>
                                Check for Images
                            </button>
                            {/* <table>
                            <tbody>
                                {cacheImages &&
                                    cacheImages.map((url) => (
                                        <tr key={url}>
                                            <td>{url}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table> */}
                        </div>
                    </div>
                    <div className="my-4">
                        {successImageMsg && <div className="mt-2 font-bold">{successImageMsg}</div>}
                        <button
                            disabled={!isOnline}
                            className="mt-2 btn btn--primary"
                            onClick={() => handleCacheImages()}
                        >
                            {cacheResetState}
                        </button>
                        {!isOnline && <p className="mt-2">You must be online to load images for offline use.</p>}
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default ModalCache;
