import { TypeObjectDetailPageProps } from '@moar/types';
import { BannerNavMain } from '../banner-nav-main/banner-nav-main';
import BannerOffline from '../banner-offline/banner-offline';
import { ProviderModalZoom } from '../context/modalZoom';
import Layout from '../layout/layout';
import ModalCache from '../modal-cache/modal-cache';
import { NavFixedMobile } from '../nav-fixed-mobile/nav-fixed-mobile';
import NavMainFullDesktop from '../nav-main-full-desktop/nav-main-full-desktop';
import NavMainFullMobile from '../nav-main-full-mobile/nav-main-full-mobile';
import ObjectDetail from '../object-detail/object-detail';
import { PageHead } from '../page-head/page-head';

export function PageDetail(props: TypeObjectDetailPageProps) {
    const {
        page,
        prevObjectSlug,
        nextObjectSlug,
        sectionColor,
        sectionColorIsTint,
        sectionTitle,
        sectionPosition,
        timelineTitle,
        preview,
        sections,
        offlineAlertBanner,
        aboutPageTitle,
        howToPageTitle,
        glossaryPageTitle,
        glossaryItemsCount,
    } = props;

    const images = page.childObjects
        .filter((obj) => obj.type === 'timeline-section-images')
        .sort((a, b) => (a.position < b.position ? -1 : a.position > b.position ? 1 : 0));

    return (
        <Layout preview={preview}>
            <BannerOffline offlineAlertBanner={offlineAlertBanner} />
            <PageHead
                defaultTitle={timelineTitle}
                title={page.object_seo_title || page.title}
                description={page.object_seo_description}
                image={page.object_seo_image}
            />
            <BannerNavMain preview={preview} title={timelineTitle} />
            <NavMainFullDesktop
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossaryItemsCount}
            />
            <NavMainFullMobile
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossaryItemsCount}
            />
            <NavFixedMobile />
            <ProviderModalZoom>
                <ObjectDetail
                    mode={sectionColorIsTint}
                    sectionColor={sectionColor}
                    sectionTitle={sectionTitle}
                    sectionPosition={sectionPosition}
                    page={page}
                    nextObjectSlug={nextObjectSlug}
                    prevObjectSlug={prevObjectSlug}
                />
            </ProviderModalZoom>
        </Layout>
    );
}

export default PageDetail;
