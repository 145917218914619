import { Transition } from '@headlessui/react';
import { IconArrowCaret } from '@moar/svg';
import { useState } from 'react';
import { useIsOnline } from '../context/online';
import { H3 } from '../headers/headers';
import TextHtml from '../text-html/text-html';
interface VideoIframeProps {
    url: string;
    title: string | undefined;
}
// either type of youtube url
const VideoIframe = (props: VideoIframeProps) => {
    const { url, title = '' } = props;

    const getId = () => {
        // share
        // https://youtu.be/bCE0RvSKdPY
        if (url.includes('youtu.be')) {
            return url.split('/')[3];
        }
        // https://www.youtube.com/watch?v=bCE0RvSKdPY
        const videoUrl = new URL(url);
        const searchParams = videoUrl.searchParams;
        const id = searchParams.get('v');
        if (id) {
            return id;
        }
        console.log('YouTube url is malformed. It is not the url or share url', url);
        return undefined;
    };

    return (
        <div className="video-responsive">
            <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${getId()}?modestbranding=1&autohide=1&showinfo=0`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={title}
            />
        </div>
    );
};

export interface BlockVideoProps {
    title?: string;
    // youtube link or mp4
    url: string;
    credit?: string;
    captionsUrl?: string | null | undefined;
    transcript?: string | null | undefined;
    transcriptTitle?: string | null | undefined;
    transcriptDescription?: string | null | undefined;
}

export function BlockVideo(props: BlockVideoProps) {
    const isOnline = useIsOnline();
    const { title, url, captionsUrl, transcript, credit, transcriptTitle = 'Video Transcript', transcriptDescription } = props;
    const [isOpen, setIsOpen] = useState(false);
    const isYouTube = url.includes('youtu');

    if (!isOnline) return null;

    return (
        <div id={'video'} className="mt-8 scroll-mt-20 md:mt-10">
            <div className="mt-4">
                {title && <H3 className="mb-4">{title}</H3>}
                {!isYouTube && (
                    <div className="w-full h-full">
                        <video className="w-full" controls crossOrigin="anonymous">
                            <source src={url} type="video/mp4" />
                            {!!captionsUrl && (
                                <track
                                    label="English"
                                    kind="subtitles"
                                    srcLang="en"
                                    src={captionsUrl} />
                            )}
                        </video>
                    </div>
                )}
                {isYouTube && <VideoIframe url={url} title={title} />}
                {credit && <p className="mt-2 text-sm">{credit}</p>}
            </div>
            {!!transcript && (
                <div className={`${credit ? 'mt-2' : 'mt-4'}`}>
                    <div className="mb-8 rounded-sm bg-ivory-dark p-7">
                        <div className="mb-2">
                            <span className="mb-2 text-base font-bold">{transcriptTitle}</span>
                            <div className="text-sm">
                                <TextHtml html={transcriptDescription} />
                            </div>
                        </div>
                        <div>
                            <Transition
                                show={isOpen}
                                enter="transition-opacity duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity duration-150"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div>
                                    <div className="mt-2 mb-6 prose-transcript">
                                        <TextHtml html={transcript} />
                                    </div>
                                </div>
                            </Transition>
                        </div>

                        <button
                            aria-label={`${isOpen ? 'Close' : 'Open'} Transcript`}
                            className="flex items-baseline font-bold text-md hover:text-black text-blue group"
                            onClick={() => setIsOpen((isOpen) => !isOpen)}
                        >
                            <span className="mr-3 text-md">{isOpen ? 'Close' : 'View'} Transcript</span>{' '}
                            <IconArrowCaret color="blue" size="sm" direction={isOpen ? 'up' : 'down'} />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BlockVideo;
