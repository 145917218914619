import { TypeSections, TypeTimeline } from '@moar/types';

interface GetImageToCacheProps {
    data: TypeTimeline;
    isMobile: boolean;
}
export const getImagesToCache = (props: GetImageToCacheProps) => {
    const { data, isMobile } = props;

    const getIntroImages = () => {
        return [data.data.intro.backgroundImageMobile.url, data.data.intro.backgroundImageDesktop.url];
    };

    const getSectionImages = () => {
        const urls = [];
        const sections = data.data.timelineSections as TypeSections;
        for (const [index, section] of sections.data.entries()) {
            if (isMobile) {
                urls.push(section.backgroundImageMobile.url);
                urls.push(section.navBackgroundImageMobile.url);
            } else {
                urls.push(section.backgroundImageDesktop.url);
                urls.push(section.navBackgroundImageDesktop.url);
            }
        }
        return urls;
    };

    // get first image url for caching
    const getObjectFirstImageUrls = () => {
        const urlArrays = [];
        const sections = data.data.timelineSections as TypeSections;
        for (const [index, section] of sections.data.entries()) {
            const objectsWithImages = section.objects.map((obj) =>
                obj.childObjects?.filter((c) => c.type === 'timeline-section-images')
            );
            const images = objectsWithImages.filter((obj) => typeof obj !== 'undefined').flat();
            const firstImages = images.filter((obj) => obj?.position === 1);
            const urls = firstImages.map((img) => img?.url);
            urlArrays.push(urls);
        }
        return urlArrays.flat();
    };

    const urls = [...getIntroImages(), ...getSectionImages(), ...getObjectFirstImageUrls()];

    console.log(urls);
    return urls;
};
