import { Fragment, useCallback, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IconClose, IconCloseCircle, IconMagnifyingGlass } from '@moar/svg';
import { useContextSearchDisplay } from '../context/searchDisplay';
import { H4 } from '../headers/headers';
import { useSearchState } from '../context/search';
import useDataSearch from '../hooks/use-data-search/use-data-search';
import useFocus from '../hooks/use-focus/use-focus';
import { slugify } from '@moar/utils';

/* eslint-disable-next-line */
export interface SlideOverSearchProps {}

export function SlideOverSearch(props: SlideOverSearchProps) {
    const [inputRef, setFocus] = useFocus();
    const [isOpen, setIsOpen] = useContextSearchDisplay();
    const { query, setQuery, filters, addFilter, removeFilter, isActive } = useSearchState();
    const { count, filtersWithCounts } = useDataSearch();

    const handleFilterChange = (filter: string) => {
        if (filters.includes(filter)) {
            removeFilter(filter);
        } else {
            addFilter(filter);
        }
    };

    const setToClose = useCallback(() => {
        if (count === 0) return;
        setIsOpen(false);
    }, [count, setIsOpen]);

    // tried to make this into a form but all sort of problems with submitting.
    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Go') {
                event.preventDefault();
                setToClose();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [count, setToClose]);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setFocus();
            }, 500);
        }
    }, [isOpen, setFocus]);

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={setToClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none md:pl-20 ">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="w-screen pointer-events-auto md:max-w-md">
                                    <div className="relative flex flex-col h-full py-8 overflow-y-scroll shadow-xl bg-ivory">
                                        <div className="px-4 sm:px-10">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="font-serif text-lg md:text-2xl">
                                                    Search
                                                </Dialog.Title>
                                                <div className="flex items-center ml-3 h-7">
                                                    <button
                                                        aria-label={`Close search panel`}
                                                        tabIndex={count === 0 ? -1 : 0}
                                                        disabled={count === 0}
                                                        type="button"
                                                        className="flex p-2 rounded-sm group focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2"
                                                        onClick={() => setIsOpen(false)}
                                                    >
                                                        <span className=" w-[12px] h-[12px]">
                                                            <IconClose />
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 px-4 mt-3 sm:px-10">
                                            <div className="relative h-full">
                                                <div className="mb-6">
                                                    <div className="relative">
                                                        <div className="absolute left-1 bottom-3">
                                                            <span className="w-[16px] h-[16px] flex">
                                                                <IconMagnifyingGlass />
                                                            </span>
                                                        </div>
                                                        {query !== '' && (
                                                            <button
                                                                aria-label="Clear search query"
                                                                onClick={() => setQuery('')}
                                                                className="absolute text-xs font-bold right-1 bottom-3"
                                                            >
                                                                Clear
                                                            </button>
                                                        )}
                                                        <input
                                                            ref={inputRef}
                                                            value={query}
                                                            onInput={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                                                setQuery(e.target.value);
                                                            }}
                                                            placeholder={'Enter your search here'}
                                                            autoComplete="off"
                                                            autoCorrect="off"
                                                            autoCapitalize="off"
                                                            spellCheck="false"
                                                            name="search"
                                                            type="text"
                                                            className="w-full pt-3 pb-2 pl-8 pr-10 font-sans text-base leading-5 border-b border-black rounded-none placeholder:text-black bg-ivory focus:outline-none focus:ring-2 focus:ring-blue"
                                                        />
                                                    </div>
                                                    <div className="mt-4">
                                                        <p className="h-4 text-sm text-black-800">
                                                            {query !== '' ? `${count} Results for "${query}"` : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <H4>Filter</H4>
                                                    {filters.length > 0 && filtersWithCounts.length === 0 && (
                                                        <p className="h-2 mt-3 text-sm text-black-800">
                                                            No filters for this query.
                                                        </p>
                                                    )}
                                                    <div className="pb-20">
                                                        <div className="h-full mt-4">
                                                            <ul className="space-y-3">
                                                                {filtersWithCounts.map((filterWithCount) => {
                                                                    const isActive = filters.includes(
                                                                        filterWithCount.label
                                                                    );
                                                                    return (
                                                                        <li
                                                                            key={`slide-over-search-filter-${slugify(
                                                                                filterWithCount.label
                                                                            )}`}
                                                                        >
                                                                            <button
                                                                                tabIndex={0}
                                                                                aria-label={`${
                                                                                    isActive ? 'Remove' : 'Select'
                                                                                } filter: ${filterWithCount.label}`}
                                                                                className={`px-6 py-3 rounded-full flex items-center text-sm  ${
                                                                                    isActive
                                                                                        ? 'bg-midnight text-white'
                                                                                        : 'bg-yellow hover:bg-yellow-dark text-black'
                                                                                }`}
                                                                                onClick={() =>
                                                                                    handleFilterChange(
                                                                                        filterWithCount.label
                                                                                    )
                                                                                }
                                                                            >
                                                                                {filterWithCount.label}{' '}
                                                                                <span className="ml-2">
                                                                                    ({filterWithCount.count})
                                                                                </span>
                                                                                {isActive && (
                                                                                    <span className="ml-3">
                                                                                        <IconCloseCircle mode="dark" />
                                                                                    </span>
                                                                                )}
                                                                            </button>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="absolute bottom-0 left-0 w-full mt-10">
                                                        <button
                                                            disabled={!isActive || count === 0}
                                                            className="btn btn--primary btn--full"
                                                            onClick={() => {
                                                                setToClose();
                                                            }}
                                                        >
                                                            See Results {isActive ? `(${count})` : ''}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default SlideOverSearch;
