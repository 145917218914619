import { IconCloseBox, IconCloseCircle } from '@moar/svg';
import { slugify } from '@moar/utils';
import { useSearchState } from '../context/search';
import { useContextSearchDisplay } from '../context/searchDisplay';
import { H4 } from '../headers/headers';
import useDataSearch from '../hooks/use-data-search/use-data-search';

/* eslint-disable-next-line */
export interface BannerSearchStateProps {}

export function BannerSearchState(props: BannerSearchStateProps) {
    const [isOpen, setIsOpen] = useContextSearchDisplay();
    const { query, filters, removeFilter, isActive, reset } = useSearchState();
    const { count } = useDataSearch();

    const scrollToTop = () => {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    };

    if (!isActive) return null;

    return (
        <div className="w-full h-auto mt-16 md:top-16 bg-midnight md:h-20">
            <div className="h-full px-0 pt-3 pb-4 md:py-5 md:flex md:items-center md:px-6 md:h-20">
                <div className="relative block w-full md:pr-14 md:items-center md:flex md:h-20">
                    <div className="pb-4 ml-6 md:pb-0 md:ml-0">
                        <H4 textColor="text-white md:whitespace-nowrap">
                            <>
                                Showing {count} Results for {query !== '' ? `"${query}"` : ''}
                            </>
                        </H4>
                    </div>

                    <div className="pt-0 mb-1 md:mb-0 md:pb-1 md:pt-2 md:mx-auto">
                        <div className="scrolling-container">
                            <div className="scrolling-inner">
                                {filters.length > 0 && (
                                    <ul className="flex px-3 pb-2 space-x-5 scrolling scrollbars--dark">
                                        {filters.map((filter) => (
                                            <li key={`search-state-filter-${slugify(filter)}`}>
                                                <button
                                                    aria-label={`Remove filter: ${filter}`}
                                                    onClick={() => {
                                                        removeFilter(filter);
                                                        scrollToTop();
                                                    }}
                                                    className="flex px-5 py-3 text-xs text-black rounded-full bg-yellow hover:bg-yellow-dark whitespace-nowrap"
                                                >
                                                    {filter}{' '}
                                                    <span className="ml-2">
                                                        <IconCloseCircle />
                                                    </span>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        {filters.length === 0 && (
                            <div className="flex items-center h-10 ml-6 text-sm text-white md:hidden">
                                <span>No filters selected</span>
                            </div>
                        )}
                        <div className={`hidden right-0 md:block top-5 md:absolute`}>
                            <button
                                aria-label={`Close and reset search`}
                                onClick={() => {
                                    reset();
                                    scrollToTop();
                                }}
                            >
                                <IconCloseBox />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button
                            tabIndex={0}
                            aria-label={`Open search panel`}
                            onClick={() => setIsOpen(true)}
                            className="ml-6 text-xs md:ml-3 link whitespace-nowrap"
                        >
                            Adjust My Search
                        </button>
                        <span className="inline ml-4 border-l md:hidden border-blue">
                            <button
                                aria-label={`Close and reset search`}
                                onClick={() => {
                                    reset();
                                    scrollToTop();
                                }}
                                className="ml-4 text-xs md:ml-0 link "
                            >
                                Clear All
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerSearchState;
