import { TypeSection, TypeTimelineObjectImage } from '@moar/types';
import Image from 'next/image';
import { Transition } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';
import AnimateFadeIn from '../animate-fade-in/animate-fade-in';
import { useIsOnline } from '../context/online';
import { useSearchState } from '../context/search';
import { H5, Hxl } from '../headers/headers';
import ImageCredit from '../image-credit/image-credit';
import ItemTimeline from '../item-timeline/item-timeline';
import { useInView } from 'react-cool-inview';
import { useElementPositions } from '../context/elementPositions';
import classNames from 'classnames';
import useResponsive from '../hooks/use-responsive/use-responsive';
import TextHtml from '../text-html/text-html';
import useScrollDirection from '../hooks/use-scroll-direction/use-scroll-direction';
import { useVerticalNavSectionOverlap } from '../context/verticalNavSectionOverlap';

export interface SectionProps {
    section: TypeSection;
    // to address search results with only one section/object where the page rides up
    onlyOneSectionToDisplay: boolean;
}

// If online, the next/image is used to fade in and give it priority
// If offline, show image saved for offline
export function Section(props: SectionProps) {
    const { section, onlyOneSectionToDisplay } = props;
    const { setFirstSectionIntroInView, firstSectionIntroInView } = useElementPositions();
    const scrollDirection = useScrollDirection();
    const { setQuery, replaceFilters } = useSearchState();
    const { currentSection } = useVerticalNavSectionOverlap();
    const { isDesktop, isMobile } = useResponsive();
    const { observe: observeIntro, inView } = useInView({
        onEnter: () => {
            setFirstSectionIntroInView(true);
            // if (index === 0) {
            //     setFirstSectionIntroInView(true);
            // }
        },
        onLeave: () => {
            setFirstSectionIntroInView(false);
            // if (index === 0) {
            //     setFirstSectionIntroInView(false);
            // }
        },
    });

    useEffect(() => {
        if (!window) return;

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params['tags']) {
            const tags = params['tags']?.split(',');
            replaceFilters(tags);
        }
        if (params['query']) {
            setQuery(params['query']);
        }
    }, []);

    const isOnline = useIsOnline();
    const { isActive: isSearchActive } = useSearchState();
    const {
        title,
        date,
        introParagraph,
        backgroundImageDesktop,
        backgroundImageMobile,
        objects,
        color,
        colorIsTint,
        creditImageMobile,
        creditImageDesktop,
        position,
    } = section;
    const mode = colorIsTint;
    const textColor = classNames({
        'text-black': mode === 'light',
        'text-white': mode === 'dark',
    });

    const getGradient = () => {
        return `linear-gradient(90.35deg, rgba(5,5,5, .95) 0.29%, rgba(5,5,5, .8) 33.21%, rgba(22,22,22, 0) 99.68%)`;
    };

    return (
        <div id={`section-${section.slug}`} className={`scroll-mt-16`}>
            {/* Seems to make nav images work on safari offline */}
            <img
                alt={section.navBackgroundImageMobile.image_alt_text}
                src={section.navBackgroundImageMobile.url}
                className="hidden object-cover w-24 h-20 md:w-[264px] md:h-[211px] rounded-l-sm "
            />
            {/* Mobile current section header */}
            {currentSection && currentSection.title === section.title && (
                <Transition
                    show={scrollDirection === 'down' && !firstSectionIntroInView}
                    appear={true}
                    enter={`transition-opacity duration-500`}
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave={`transition-opacity duration-500`}
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className={`transition-all duration-1000 lg:hidden z-30 w-full fixed py-3 top-0 text-center h-16 ${
                        section.colorIsTint === 'dark' ? 'text-white' : 'text-midnight'
                    }`}
                    style={{ backgroundColor: section.color }}
                >
                    <h5 className="text-sm font-bold">{section.date}</h5>
                    <h2 className="font-serif text-lg">{section.title}</h2>
                </Transition>
            )}
            {isSearchActive && (
                <div
                    className="relative"
                    style={{
                        backgroundColor: color.replace(', 1)', ', 0.75)'),
                    }}
                >
                    <div className="xl:container">
                        <div className={`py-10`}>
                            <H5 textColor={textColor} className="text-center">
                                {date}
                            </H5>
                            <Hxl textColor={textColor} Tag="h2" className="text-center">
                                {title}
                            </Hxl>
                        </div>
                    </div>
                </div>
            )}

            {!isSearchActive && (
                <div
                    id={`section-intro-${section.slug}`}
                    className="relative"
                    style={{
                        backgroundColor: color.replace(', 1)', ', 0.9)'),
                    }}
                >
                    <div className="">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12">
                                <div className="relative">
                                    <div className={`w-full h-[720px]  md:mb-0`} ref={observeIntro}>
                                        <div
                                            className="absolute top-0 left-0 z-[9] hidden w-[600px] h-full lg:block"
                                            style={{ background: getGradient() }}
                                        ></div>

                                        {/* Removed use of next image when online using these when offline to fix safari mobile offline issues */}
                                        <div className="relative h-[720px]">
                                            {/* Need to load each one specifically and not use bgImage - safari mobile issues offline */}
                                            <img
                                                alt={backgroundImageMobile.image_alt_text}
                                                src={backgroundImageMobile.url}
                                                className="block lg:hidden object-cover w-screen h-[720px]"
                                            />
                                            <img
                                                alt={backgroundImageDesktop.image_alt_text}
                                                src={backgroundImageDesktop.url}
                                                className="hidden lg:block object-cover w-screen h-[720px]"
                                            />
                                        </div>

                                        <AnimateFadeIn>
                                            <div
                                                className="absolute left-0 right-0 z-10 p-5 mx-5 duration-1000 md:mx-0 top-40 md:left-auto md:p-10 md:max-w-md md:right-10 lg:right-40 md:top-60"
                                                style={{ backgroundColor: color.replace(', 1)', ', 0.9)') }}
                                            >
                                                <div
                                                    className={`${textColor} font-sans font-normal text-center md:text-left text-base-plus `}
                                                >
                                                    {date}
                                                </div>
                                                <h2
                                                    className={`${textColor} font-serif text-5xl text-center  md:text-8xl md:text-left`}
                                                >
                                                    {title}
                                                </h2>
                                                <TextHtml
                                                    mode={mode}
                                                    className={`${textColor} mt-4 font-sans text-base font-normal text-center md:text-left`}
                                                    html={introParagraph}
                                                    sectionSlug={`section-intro-${section.slug}`}
                                                ></TextHtml>
                                            </div>
                                        </AnimateFadeIn>

                                        <ImageCredit
                                            creditText={isMobile ? creditImageMobile : creditImageDesktop}
                                            mode="section"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div
                className={`relative ${onlyOneSectionToDisplay ? 'min-h-page' : ''}`}
                style={{ backgroundColor: color.replace(', 1)', ', 0.75)') }}
            >
                {objects.map((obj, index) => (
                    <ItemTimeline
                        key={obj.id}
                        obj={obj}
                        color={color}
                        mode={mode}
                        sectionPosition={position}
                        isLast={objects.length - 1 === index}
                    />
                ))}
            </div>
        </div>
    );
}
