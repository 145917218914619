import { IconGear, IconMagnifyingGlass, IconStar } from '@moar/svg';
import { useRouter } from 'next/router';
import { useContextModalCacheDisplay } from '../context/modalCacheDisplay';
import { useContextMyTimelineDisplay } from '../context/myTimelineDisplay';
import useMyTimelineItems from '../context/myTimelineItems';
import { useSearchState } from '../context/search';
import { useContextSearchDisplay } from '../context/searchDisplay';

export interface NavFixedMobileProps {
    disableSearch?: boolean;
}

export function NavFixedMobile(props: NavFixedMobileProps) {
    const [, setIsModalOpen] = useContextModalCacheDisplay();
    const { disableSearch = true } = props;
    const [, setIsOpen] = useContextSearchDisplay();
    const [, setMyTimelineIsOpen] = useContextMyTimelineDisplay();
    const { isActive: isSearchActive } = useSearchState();
    const { items } = useMyTimelineItems();
    const router = useRouter();
    const count = items?.length;
    const isMyTimelinePage = router.pathname === '/my-timeline';

    return (
        <div className="fixed z-30 block w-full h-18 -bottom-1 md:hidden bg-midnight print:hidden">
            <div className="px-10 pt-4 pb-4 sm:px-6">
                <div className="">
                    <div className="flex items-center justify-around h-full pb-2">
                        <button
                            disabled={disableSearch}
                            className={`${
                                isSearchActive ? 'text-yellow' : 'text-white'
                            } flex flex-col items-center text-xs text-white group hover:text-yellow disabled:text-blue-300`}
                            onClick={() => setIsOpen(true)}
                        >
                            <span className="w-[12px] h-[12px]">
                                <IconMagnifyingGlass
                                    color={disableSearch ? 'light-blue' : isSearchActive ? 'yellow' : 'white'}
                                />
                            </span>
                            <span
                                className={`${
                                    isSearchActive && !disableSearch ? 'border-yellow' : 'border-transparent'
                                }  border-yellow border-b-2 mt-2 pb-2`}
                            >
                                Search & Filter
                            </span>
                        </button>
                        <button
                            className={`${
                                isMyTimelinePage || (items && items.length > 0) ? 'text-yellow' : 'text-white'
                            } flex flex-col items-center text-xs group hover:text-yellow`}
                            onClick={() => setMyTimelineIsOpen(true)}
                        >
                            <span className="w-[12px] h-[14px]">
                                <IconStar color={`${isMyTimelinePage ? 'yellow' : 'white'}`} solid={false} />
                            </span>
                            <div
                                className={`${
                                    isMyTimelinePage ? 'border-yellow' : 'border-transparent'
                                }  border-yellow border-b-2 pb-2 mt-2`}
                            >
                                <span>My Timeline</span>
                                {items && items.length > 0 && (
                                    <span className={`text-yellow pl-2 mt-[2px] ml-2 border-l border-blue`}>
                                        {count}
                                    </span>
                                )}
                            </div>
                        </button>

                        <button
                            className={`mode--standalone text-white flex flex-col items-center justify-center text-xs group`}
                            onClick={() => setIsModalOpen(true)}
                        >
                            <div className="flex justify-center">
                                <span className="h-[12px] w-[12px]">
                                    <IconGear />
                                </span>
                            </div>
                            <div className={`border-transparent border-b-2 mt-2 pb-2`}>Settings</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
