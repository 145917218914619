/* eslint-disable-next-line */
export interface IconCloseBoxProps {}

export function IconCloseBox(props: IconCloseBoxProps) {
    return (
        <svg
            className="group"
            aria-hidden={true}
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="fill-yellow group-hover:fill-yellow-dark active:fill-yellow-md"
                d="M0 2C0 0.895431 0.895431 0 2 0H34C35.1046 0 36 0.895431 36 2V34C36 35.1046 35.1046 36 34 36H2C0.895431 36 0 35.1046 0 34V2Z"
            />
            <path
                d="M23.4347 12.8812L23.4343 12.8816L19.4371 16.8788C18.8178 17.4981 18.8178 18.5019 19.4371 19.1212L23.4343 23.1184L23.4347 23.1188C23.5215 23.2054 23.5222 23.3464 23.4343 23.4343C23.3464 23.5222 23.2054 23.5215 23.1188 23.4347L23.1184 23.4343L19.1212 19.4371C18.5019 18.8178 17.4981 18.8178 16.8788 19.4371L12.8816 23.4343L12.8812 23.4347C12.7946 23.5215 12.6536 23.5222 12.5657 23.4343C12.4778 23.3464 12.4785 23.2054 12.5653 23.1188L12.5657 23.1184L16.5629 19.1212C17.1822 18.5019 17.1822 17.4981 16.5629 16.8788L12.5657 12.8816L12.5653 12.8812C12.4785 12.7946 12.4778 12.6536 12.5657 12.5657C12.6536 12.4778 12.7946 12.4785 12.8812 12.5653L12.8816 12.5657L16.8788 16.5629C17.4981 17.1822 18.5019 17.1822 19.1212 16.5629L23.1184 12.5657L23.1188 12.5653C23.2054 12.4785 23.3464 12.4778 23.4343 12.5657C23.5222 12.6536 23.5215 12.7946 23.4347 12.8812Z"
                fill="#1A1A1A"
                stroke="#254468"
            />
        </svg>
    );
}

export default IconCloseBox;
