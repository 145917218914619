import classNames from 'classnames';

/* eslint-disable-next-line */
export interface IconArrowCaretProps {
    color?: 'black' | 'white' | 'yellow' | 'blue';
    direction?: 'left' | 'right' | 'down' | 'up';
    size?: 'sm' | 'base';
}

export function IconArrowCaret(props: IconArrowCaretProps) {
    const { color = 'yellow', direction = 'right', size = 'base' } = props;

    const rotateClass = classNames({
        'rotate-180': direction === 'left',
        'rotate-90': direction === 'down',
        '-rotate-90': direction === 'up',
    });

    const sizeClass = classNames({
        'w-[9px] h-[12px]': size === 'sm',
        'w-[12px] h-[15px]': size === 'base',
    });

    const fillClass = classNames({
        'fill-yellow hover:fill-yellow-dark group-hover:fill-yellow-dark': color === 'yellow',
        'fill-white hover:fill-white group-hover:fill-yellow': color === 'white',
        'fill-black hover:fill-blue group-hover:fill-blue': color === 'black',
        'fill-blue hover:fill-black group-hover:fill-black': color === 'blue',
    });

    return (
        <span className={`${sizeClass}`}>
            <svg
                aria-hidden="true"
                className={`${rotateClass} w-full h-full transition-all`}
                viewBox="0 0 9 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className={fillClass}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.834312 1.03008C0.570055 0.902733 0.238201 0.980474 0.0817427 1.20638C-0.0747159 1.43228 -0.000192451 1.72608 0.250745 1.87265C0.898878 2.25123 1.87692 2.91812 2.68561 3.67389C3.08963 4.05147 3.439 4.43981 3.68461 4.81502C3.93291 5.19435 4.05332 5.52699 4.05332 5.80272C4.05332 6.08164 3.93063 6.43344 3.67743 6.84488C3.42787 7.25042 3.07408 7.67772 2.66717 8.09763C1.85298 8.9378 0.869541 9.70403 0.217396 10.1484C-0.0176195 10.3086 -0.0696686 10.6014 0.0986231 10.8166C0.266915 11.0318 0.597349 11.0949 0.852652 10.9607L8.18005 7.10875C9.29739 6.52137 9.26677 5.09356 8.12503 4.54337L0.834312 1.03008Z"
                />
            </svg>
        </span>
    );
}

export default IconArrowCaret;
