import Head from 'next/head';
import { useEffect, useState } from 'react';
import { useIsPwa } from '../context/pwaContext';

type PageHeadProps = {
    image?: string | null;
    defaultTitle: string;
    title?: string | null;
    description?: string | null;
    keywords?: string | null;
    noindex?: boolean;
    preloadImage?: string;
};

export const PageHead = (props: PageHeadProps) => {
    const { image, title = '', description = '', keywords = '', noindex = false, defaultTitle, preloadImage } = props;
    const [pageUrl, setPageUrl] = useState<string>('');
    const isPwa = useIsPwa();

    useEffect(() => {
        setPageUrl(window.location.href);
    }, []);

    const pageTitle = title === null ? '' : `${title} | `;
    const fullTitle = `${pageTitle}${defaultTitle}`;

    return (
        <Head>
            {isPwa && <link rel="manifest" href={`/manifest.json`}></link>}
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
            />
            <title>{fullTitle}</title>
            {noindex && <meta name="robots" content="noindex,nofollow" />}
            {description && <meta name="description" content={description} key="description" />}
            {keywords && <meta name="keywords" content={keywords} />}
            {preloadImage && <link rel="preload" href={preloadImage} as="image" />}
            <link rel="shortcut icon" href={`/favicon.ico`} />

            {isPwa && (
                <>
                    <link href={`/icons/icon-16.png`} rel="icon" type="image/png" sizes="16x16" />
                    <link href={`/icons/icon-32.png`} rel="icon" type="image/png" sizes="32x32" />
                    <link rel="apple-touch-icon" href={`/icons/icon-512.png`} />
                    <link rel="apple-touch-icon" sizes="152x152" href={`/icons/icon-152.png`} />
                    <link rel="apple-touch-icon" sizes="192x192" href={`/icons/icon-192.png`} />
                    <link rel="apple-touch-icon" href={`/icons/icon-512.png`}></link>
                    <link rel="mask-icon" href={`/icons/maskable_icon_x512.png`} color="#4C719B" />
                    <meta name="application-name" content={defaultTitle} />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                    <meta name="apple-mobile-web-app-title" content={defaultTitle} />

                    <meta name="format-detection" content="telephone=no" />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <meta name="msapplication-config" content={`/icons/browserconfig.xml`} />
                    <meta name="msapplication-TileColor" content="#4C719B" />
                    <meta name="msapplication-tap-highlight" content="no" />
                    <meta name="theme-color" content="#172535" />
                </>
            )}

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:url" content={pageUrl} />
            <meta name="twitter:title" content={title ? title : ''} />
            {description && <meta name="twitter:description" content={description} />}
            {image && <meta name="twitter:image" content={image} />}

            <meta property="og:site_name" content={defaultTitle} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title ? title : ''} />
            {description && <meta property="og:description" content={description} />}
            <meta property="og:url" content={pageUrl} />
            {image && <meta property="og:image" content={image} />}
        </Head>
    );
};
