import { Transition } from '@headlessui/react';
import { IconArrowCaret } from '@moar/svg';
import { TypeAudio } from '@moar/types';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface AudioBlockProps {
    audio: TypeAudio;
}

export function AudioBlock(props: AudioBlockProps) {
    const { audio } = props;
    const [isOpen, setIsOpen] = useState(false);
    const { description, title, url, transcription } = audio;
    return (
        <div className="mb-4 rounded-sm py-7 bg-ivory-dark">
            <div className="px-7">
                <h4 className="font-sans text-base font-bold">{title}</h4>
                <div className="mt-2 mb-2 prose-media" dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
            <div className="mt-4 flex justify-center md:justify-start md:ml-7">
                <audio controls src={url}>
                    Your browser does not support the
                    <code>audio</code> element.
                </audio>
            </div>
            <div className="mt-6 px-7">
                {!!transcription && (
                    <>
                        <div>
                            <Transition
                                show={isOpen}
                                enter="transition-opacity duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity duration-150"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div>
                                    <h4 className="text-base font-bold">Transcript</h4>
                                    <div
                                        className="mt-2 mb-6 prose-transcript"
                                        dangerouslySetInnerHTML={{ __html: transcription }}
                                    ></div>
                                </div>
                            </Transition>
                        </div>

                        <button
                            aria-label={`${isOpen ? 'Close' : 'Open'} Transcript`}
                            className="flex items-baseline font-bold text-md hover:text-black text-blue group"
                            onClick={() => setIsOpen((isOpen) => !isOpen)}
                        >
                            <span className="mr-3">{isOpen ? 'Close' : 'View'} Transcript</span>{' '}
                            <IconArrowCaret color="blue" size="sm" direction={isOpen ? 'up' : 'down'} />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}

export default AudioBlock;
