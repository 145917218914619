import { TypeTimelineObject, TypeTimelineObjectImage } from '@moar/types';
import classNames from 'classnames';
import Link from 'next/link';
import ButtonToggleMyTimeline from '../button-toggle-my-timeline/button-toggle-my-timeline';
import { H5 } from '../headers/headers';
import useResponsive from '../hooks/use-responsive/use-responsive';
import { useInView } from 'react-cool-inview';
import { useRouter } from 'next/router';
import TextHtml from '../text-html/text-html';
import { useSearchState } from '../context/search';
import { MouseEvent, useMemo } from 'react';
import { IconArrow, IconVideo } from '@moar/svg';
import { useIsPwa } from '../context/pwaContext';

export interface ObjectStandardFeaturedProps {
    obj: TypeTimelineObject;
    color: string;
    mode?: 'light' | 'dark';
    sectionPosition: number;
    imagePlacement: 'left' | 'right';
    objectType: 'standard' | 'featured';
    isLast: boolean;
}

export function ObjectStandardFeatured(props: ObjectStandardFeaturedProps) {
    const { isMobileDesign, isMobile } = useResponsive();
    const { isActive } = useSearchState();
    const isPwa = useIsPwa();
    const router = useRouter();
    const { observe, inView } = useInView({
        // fixes object not loading if page is scrolled and object is not fully on screen
        delay: 200,
        unobserveOnEnter: true,
        rootMargin: `${isMobile ? '-200px' : '0px'} 0px`,
    });

    const { obj, color, mode, sectionPosition, imagePlacement, objectType, isLast } = props;
    const { date, title, timeline_excerpt, childObjects, slug, tags, position } = obj;

    const textColor = classNames({
        'text-white group-hover:text-yellow transition-all': mode === 'dark',
        'text-black group-hover:text-blue transition-all': mode === 'light',
    });

    const linkColor = classNames({
        'text-white hover:text-yellow transition-all': mode === 'dark',
        'text-blue hover:text-blue': mode === 'light',
    });

    const borderColorFeatured = classNames({
        'border-dark opacity-30': mode === 'light',
        'border-white': mode === 'dark',
    });

    const getGradient = () => {
        if (objectType === 'featured') {
            const opacity = mode === 'dark' ? '0.3' : '0.2';
            const gradientStart = `rgba(255, 255, 255, ${opacity})`;
            const gradientStop = color.replace(', 1)', ', 0.0)');
            return `linear-gradient(${gradientStart}, ${gradientStop})`;
        }
        const gradientStart = color.replace(', 1)', ', 0.5)');
        const gradientStop = color.replace(', 1)', ', 0.0)');
        return `linear-gradient(${gradientStart}, ${gradientStop})`;
    };

    const gradient = getGradient();

    const images = childObjects
        .filter((obj) => obj.type === 'timeline-section-images')
        .sort((a, b) => (a.position < b.position ? -1 : a.position > b.position ? 1 : 0)) as TypeTimelineObjectImage[];

    const videos = childObjects
        .filter((obj) => obj.type === 'timeline-section-videos');

    // add object hash to route to make back button go back to object
    const handleGoToObject = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        router.push(`/sections/#${slug}`, undefined, { shallow: true, scroll: false });
        router.push(`/objects/${slug}`);
    };

    const handleGoToObjectAnchor = (e: MouseEvent<HTMLAnchorElement>, anchor: string | null) => {
        e.preventDefault();
        router.push(`/sections/#${slug}`, undefined, { shallow: true, scroll: false });
        router.push(`/objects/${slug}/#${anchor}`);
    };

    const paddingClasses = useMemo(() => {
        if (isLast) return '';
        if (position === 1 || isActive) {
            return 'md:mb-25px lg:mb-[50px] pt-0 md:pt-[25px]';
        }
        return 'md:my-[25px] lg:my-[50px]';
    }, [isLast, position, isActive]);

    return (
        <div
            className={`mx-0 object-${objectType} scroll-mt-16 overflow-hidden ${
                inView ? 'animate-fade-in' : 'opacity-0'
            }  ${paddingClasses}`}
            id={`${slug}`}
            ref={observe}
        >
            <div style={{ backgroundColor: objectType === 'featured' ? color : 'transparent' }}>
                <div className={`py-8 md:mx-10 md:py-[25px] lg:py-[50px] my-0 2xl:container`}>
                    <div className="grid grid-cols-12 gap-0 lg:grid-cols-12 md:gap-10">
                        <div className="col-span-12 col-start-1 lg:col-span-10 lg:col-start-3">
                            <div className={`grid grid-cols-10 md:grid-cols-8 lg:grid-cols-10 gap-0 md:gap-10`}>
                                {/* IMAGE */}
                                <div
                                    className={`px-5 col-span-10 lg:px-0 md:col-span-4 lg:col-span-5 ${
                                        imagePlacement === 'left' ? 'md:order-first' : 'md:order-last'
                                    }`}
                                >
                                    {!isMobile && images[0] && images[0].url && (
                                        <div className={`relative`}>
                                            <div
                                                className={`block md:max-w-[375px] md:h-[375px] lg:max-w-[513px] lg:h-[513px] mx-auto`}
                                            >
                                                <Link href={`/objects/${slug}`}>
                                                    <a
                                                        onClick={(e) => handleGoToObject(e)}
                                                        aria-label={`View details about ${title}`}
                                                    >
                                                        <div className="image-wrapper">
                                                            <img
                                                                alt={images[0].image_alt_text}
                                                                src={
                                                                    isPwa
                                                                        ? images[0].url
                                                                        : images[0].url.replace('&pwaCache=true', '')
                                                                }
                                                                className="object-contain transition-all duration-500 hover:scale-[1.04]"
                                                            />
                                                        </div>
                                                    </a>
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                    {isMobile && images[0] && images[0].url && (
                                        <div
                                            className={`px-6 mb-6 rounded-sm pt-6 md:pt-0`}
                                            style={{
                                                background: gradient,
                                            }}
                                        >
                                            <div
                                                className={`relative`}
                                                style={{
                                                    height: '290px',
                                                }}
                                            >
                                                <span className="image-wrapper">
                                                    <Link href={`/objects/${slug}`}>
                                                        <a onClick={(e) => handleGoToObject(e)}>
                                                            <img
                                                                alt={images[0].image_alt_text}
                                                                src={images[0].url}
                                                                className="object-contain"
                                                            />
                                                        </a>
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/* TEXT */}
                                <div
                                    className={`col-span-10 md:col-span-4 ${
                                        imagePlacement === 'left' ? 'lg:col-span-4' : 'lg:col-span-5 lg:mr-8'
                                    }`}
                                >
                                    <div
                                        className={`px-5 lg:px-0 flex flex-col justify-center h-full ${
                                            imagePlacement === 'right' ? 'lg:ml-6' : ''
                                        }`}
                                    >
                                        <div className="mb-1">
                                            <ButtonToggleMyTimeline
                                                justIcon={true}
                                                mode={mode}
                                                item={{
                                                    id: obj.id,
                                                    title,
                                                    date,
                                                    image: images[0],
                                                    position,
                                                    sectionPosition,
                                                    slug,
                                                }}
                                            />
                                        </div>
                                        <Link href={`/objects/${slug}`}>
                                            <a
                                                onClick={(e) => handleGoToObject(e)}
                                                aria-label={`View details about ${title}`}
                                                className={`${linkColor} transition-all group`}
                                            >
                                                <div className="space-y-1">
                                                    <H5 Tag="span" textColor={textColor}>
                                                        {date}
                                                    </H5>
                                                    <h3
                                                        className={`text-3xl transition-all font-serif md:text-4xl ${textColor} `}
                                                    >
                                                        {title}
                                                    </h3>
                                                    <h4
                                                        className={`pt-1 ${textColor} transition-all  mb-3 text-base font-sans font-normal`}
                                                    >
                                                        {timeline_excerpt}
                                                    </h4>
                                                </div>
                                                <div className="my-4">
                                                    <div
                                                        className={`border-t ${
                                                            objectType === 'featured' ? borderColorFeatured : ''
                                                        }`}
                                                        style={{
                                                            borderColor:
                                                                objectType === 'standard' ? color : 'transparent',
                                                        }}
                                                    ></div>
                                                </div>
                                                <span className={`text-sm font-bold flex items-center`}>
                                                    View Details{' '}
                                                    <span className="ml-2">
                                                        <IconArrow
                                                            direction="right"
                                                            color={mode === 'dark' ? 'white' : 'blue'}
                                                        />
                                                    </span>
                                                </span>
                                            </a>
                                        </Link>
                                        { videos.length > 0 && (
                                            <div className="ml-auto block -mt-4">
                                                <Link href={`/objects/${slug}/#video`}>
                                                    <a
                                                        onClick={(e) => handleGoToObjectAnchor(e, 'video')}
                                                        aria-label={`View video about ${title}`}
                                                        className={`${linkColor} transition-all group`}
                                                    >
                                                        <IconVideo color={mode === 'dark' ? 'white' : 'blue'} />
                                                    </a>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ObjectStandardFeatured;
