import classNames from 'classnames';

/* eslint-disable-next-line */
export interface IconDownloadProps {
    color?: 'blue' | 'black';
}

export function IconDownload(props: IconDownloadProps) {
    const { color = 'blue' } = props;

    const strokeClass = classNames({
        'stroke-blue hover:stroke-black group-hover:stroke-black': color === 'blue',
        'stroke-midnight': color === 'black',
    });

    return (
        <svg
            aria-hidden={true}
            className={strokeClass}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M13.3327 7.33325L7.99935 12.6666L2.66602 7.33325" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 12.6666V0.666626" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.3327 15.3333H0.666016" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default IconDownload;
