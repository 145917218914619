import { IconPause, IconPlay } from '@moar/svg';
import { TypeIntroImage } from '@moar/types';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { useIsOnline } from '../context/online';
import useWindowSize from '../hooks/use-window-size/use-window-size';

export interface BackgroundProps {
    backgroundImage: TypeIntroImage;
    backgroundVideo?: string;
    captionsUrl?: string | null | undefined;
}
export function IntroBackground(props: BackgroundProps) {
    const [isPaused, setIsPaused] = useState(false);
    const windowSize = useWindowSize();
    const { backgroundImage, backgroundVideo, captionsUrl } = props;
    const isOnline = useIsOnline();
    const showVideo = backgroundVideo && isOnline;
    const videoRef = useRef<HTMLVideoElement>(null);

    const handleTogglePlay = () => {
        if (!videoRef.current) return;
        if (videoRef.current.paused) {
            videoRef.current?.play();
        } else {
            videoRef.current?.pause();
        }
        setIsPaused(!isPaused);
    };

    const introHeight = windowSize && windowSize.height && windowSize.height > 0 ? `${windowSize.height}px` : '100vh';

    if (showVideo) {
        return (
            <>
                <button
                    tabIndex={0}
                    aria-label={isPaused ? 'Play Video' : 'Pause Video'}
                    className="absolute z-10 font-bold text-white bottom-3 left-3 md:bottom-8 md:left-10 betterhover:hover:text-yellow group"
                    onClick={() => handleTogglePlay()}
                >
                    <div className="flex items-center">
                        <span className="mr-2">
                            {isPaused && <IconPlay />}
                            {!isPaused && <IconPause />}
                        </span>
                        {isPaused ? 'Play Video' : 'Pause Video'}
                    </div>
                </button>
                <video
                    ref={videoRef}
                    className="absolute top-0 left-0 object-cover w-screen"
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{ height: introHeight }}
                    crossOrigin="anonymous"
                >
                    <source src={backgroundVideo} type="video/mp4" />
                    {!!captionsUrl && (
                        <track
                            label="English"
                            kind="subtitles"
                            srcLang="en"
                            src={captionsUrl} />
                    )}
                </video>
            </>
        );
    }
    return (
        <img
            alt={backgroundImage.image_alt_text}
            src={backgroundImage.url}
            className="absolute top-0 left-0 object-cover w-screen"
            style={{ height: introHeight }}
        />
    );
}

export default IntroBackground;
