/* eslint-disable-next-line */
export interface IconYoutubeProps {}

export function IconYoutube(props: IconYoutubeProps) {
    return (
        <svg
            aria-hidden={true}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.8667 4.7999C15.8667 4.7999 15.7333 3.66657 15.2 3.1999C14.6 2.53324 13.9333 2.53324 13.6 2.53324C11.3333 2.3999 8 2.3999 8 2.3999C8 2.3999 4.66667 2.3999 2.4 2.53324C2.06667 2.5999 1.4 2.5999 0.8 3.1999C0.333333 3.66657 0.133333 4.7999 0.133333 4.7999C0.133333 4.7999 0 6.06657 0 7.3999V8.5999C0 9.86657 0.133333 11.1999 0.133333 11.1999C0.133333 11.1999 0.266667 12.3332 0.8 12.7999C1.4 13.4666 2.2 13.3999 2.53333 13.4666C3.8 13.5999 8 13.5999 8 13.5999C8 13.5999 11.3333 13.5999 13.6 13.3999C13.9333 13.3332 14.6 13.3332 15.2 12.7332C15.6667 12.2666 15.8667 11.1332 15.8667 11.1332C15.8667 11.1332 16 9.86657 16 8.53324V7.33324C16 6.06657 15.8667 4.7999 15.8667 4.7999ZM6.33333 10.0666V5.5999L10.6667 7.86657L6.33333 10.0666Z"
                className="fill-white group-hover:fill-yellow"
            />
        </svg>
    );
}

export default IconYoutube;
