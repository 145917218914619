import classNames from 'classnames';

/* eslint-disable-next-line */
export interface IconZoomProps {
    color?: 'yellow' | 'midnight';
}

export function IconZoom(props: IconZoomProps) {
    const { color = 'yellow' } = props;

    const colorClasses = classNames({
        'fill-yellow group-hover:fill-yellow-dark': color === 'yellow',
        'fill-midnight group-hover:black': color === 'midnight',
    });

    return (
        <svg
            aria-hidden={true}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className={colorClasses}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 7.5C2 4.46243 4.46243 2 7.5 2C10.5376 2 13 4.46243 13 7.5C13 10.5376 10.5376 13 7.5 13C4.46243 13 2 10.5376 2 7.5ZM7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14C9.11495 14 10.5923 13.411 11.7291 12.4362L14.6464 15.3536C14.8417 15.5488 15.1583 15.5488 15.3536 15.3536C15.5488 15.1583 15.5488 14.8417 15.3536 14.6464L12.4362 11.7291C13.411 10.5923 14 9.11495 14 7.5C14 3.91015 11.0899 1 7.5 1Z"
            />
            <path
                className={colorClasses}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 4.5C8 4.22386 7.77614 4 7.5 4C7.22386 4 7 4.22386 7 4.5V7H4.5C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H7V10.5C7 10.7761 7.22386 11 7.5 11C7.77614 11 8 10.7761 8 10.5V8H10.5C10.7761 8 11 7.77614 11 7.5C11 7.22386 10.7761 7 10.5 7H8V4.5Z"
            />
        </svg>
    );
}

export default IconZoom;
