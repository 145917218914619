import classNames from 'classnames';

/* eslint-disable-next-line */
export interface IconICircleProps {
    color?: 'yellow' | 'midnight';
}

export function IconICircle(props: IconICircleProps) {
    const { color = 'yellow' } = props;

    const colorClasses = classNames({
        'stroke-yellow group-hover:stroke-yellow-dark': color === 'yellow',
        'stroke-midnight group-hover:black': color === 'midnight',
    });

    return (
        <svg aria-hidden={true} className="w-4" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className={colorClasses}
                d="M9 5C9 5.27614 8.77614 5.5 8.5 5.5C8.22386 5.5 8 5.27614 8 5C8 4.72386 8.22386 4.5 8.5 4.5C8.77614 4.5 9 4.72386 9 5Z"
                fill="black"
            />
            <path
                d="M8.5 15.5C12.366 15.5 15.5 12.366 15.5 8.5C15.5 4.63401 12.366 1.5 8.5 1.5C4.63401 1.5 1.5 4.63401 1.5 8.5C1.5 12.366 4.63401 15.5 8.5 15.5Z"
                className={colorClasses}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.5 12.5V8.5"
                className={colorClasses}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default IconICircle;
