import { createContext, ReactNode, useContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ContextMyTimelineDisplay = createContext<[boolean, (isOpen: boolean) => void]>([false, () => {}]);

export function ProviderMyTimelineDisplay({ children }: { children: ReactNode }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <ContextMyTimelineDisplay.Provider value={[isOpen, setIsOpen]}>{children}</ContextMyTimelineDisplay.Provider>
    );
}

export function useContextMyTimelineDisplay() {
    return useContext(ContextMyTimelineDisplay);
}
