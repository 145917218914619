import { createContext, ReactNode, useContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ContextSearchDisplay = createContext<[boolean, (isOpen: boolean) => void]>([false, () => {}]);

export function ProviderSearchDisplay({ children }: { children: ReactNode }) {
    const [isOpen, setIsOpen] = useState(false);
    return <ContextSearchDisplay.Provider value={[isOpen, setIsOpen]}>{children}</ContextSearchDisplay.Provider>;
}

export function useContextSearchDisplay() {
    return useContext(ContextSearchDisplay);
}

// const useSearchFilterStateContext = () => useContext(SearchFilterStateContext);

// export const useSearchFilterToogle = (): [boolean, () => void] => {
//     const [isOpen, setIsOpen] = useSearchFilterStateContext();

//     const toggleIsOpen = () => {
//         console.log('toggle');
//         if (isOpen) {
//             document.body.style.removeProperty('overflow');
//         } else {
//             document.body.style.setProperty('overflow', 'hidden');
//         }
//         setIsOpen(!isOpen);
//     };
//     return [isOpen, toggleIsOpen];
// };
