import classNames from 'classnames';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useIsOnline } from '../context/online';
import useGlossary from '../hooks/use-glossary/use-glossary';

const ContainerStyled = styled.div`
    a {
        ${(props: { linkColor ?: string }) => props.linkColor ? `color: ${props.linkColor} !important` : ''};
    }
`;

/* eslint-disable-next-line */
export interface TextHtmlProps {
    html: any;
    mode?: 'light' | 'dark';
    linkColor?: string;
    sectionSlug?: string;
}

export function TextHtml(props: TextHtmlProps & React.HTMLAttributes<HTMLDivElement>) {
    const { html, mode = 'light', className = '', linkColor, sectionSlug, ...rest } = props;
    const [loaded, setLoaded] = useState(false);
    const textRef = useRef<HTMLInputElement>(null);
    const isOnline = useIsOnline();
    useGlossary({ ref: textRef, rerenderTrigger: loaded, sectionSlug });

    useEffect(() => {
        setLoaded(true);
    }, []);

    const glossaryLinkColor = classNames({
        'html-block--light': mode === 'light',
        'html-block--dark': mode === 'dark',
    });

    const contentClickHandler = (e: MouseEvent) => {
        if (!isOnline) {
            e.preventDefault();
        }
    };

    return (
        <ContainerStyled
            key={loaded.toString()}
            className={`${className} ${glossaryLinkColor}`}
            {...rest}
            ref={textRef}
            linkColor={linkColor}
            onClick={contentClickHandler}
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
}

export default TextHtml;
