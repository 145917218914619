import classNames from 'classnames';

/* eslint-disable-next-line */
export interface IconArrowCircleProps {}

export function IconArrowCircle(props: IconArrowCircleProps) {
    return (
        <svg
            aria-hidden={true}
            width="61"
            height="61"
            viewBox="0 0 61 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_1563_16147)">
                <circle cx="30.5" cy="30.5" r="20.5" fill="white" />
            </g>
            <path d="M31.334 37.334L31.334 24.0007" stroke="#254468" strokeMiterlimit="10" />
            <path
                d="M26.667 28.668L31.3337 24.0013L36.0003 28.668"
                stroke="#254468"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
            <defs>
                <filter
                    id="filter0_d_1563_16147"
                    x="0"
                    y="0"
                    width="61"
                    height="61"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1563_16147" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1563_16147" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}

export default IconArrowCircle;
