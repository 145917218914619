import { createContext, ReactNode, useContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ContextModalCacheDisplay = createContext<[boolean, (isOpen: boolean) => void]>([false, () => {}]);

export function ProviderModalCacheDisplay({ children }: { children: ReactNode }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <ContextModalCacheDisplay.Provider value={[isOpen, setIsOpen]}>{children}</ContextModalCacheDisplay.Provider>
    );
}

export function useContextModalCacheDisplay() {
    return useContext(ContextModalCacheDisplay);
}
