import Link from 'next/link';
import { useState } from 'react';
import { hideAll } from 'tippy.js';
import Tippy from '@tippyjs/react';

import { IconArrowCaret } from '@moar/svg';
import { TypeHotspot } from '@moar/types';
import { useIsOnline } from '../context/online';
import useResponsive from '../hooks/use-responsive/use-responsive';

export interface HotspotInfoboxProps {
    hotspot: TypeHotspot;
}

// see styling in /libs/ui/src/lib/styles/components/tooltips.css
function HotSpotInfobox(props: HotspotInfoboxProps) {
    const { title, description, cta_label, cta_url } = props.hotspot;
    const isOnline = useIsOnline();

    return (
        <div className="tooltip text-white bg-midnight p-4 h-auto w-[300px] md:w-[320px] transition-all" tabIndex={0}>
            {title && <span className="text-base font-bold md:text-md">{title}</span>}
            {description && <p className="mt-2 text-sm font-normal md:text-md">{description}</p>}
            {cta_label && isOnline && (
                <Link href={cta_url}>
                    <a
                        className="flex items-center justify-center mt-2 font-bold hover:text-yellow group"
                        target={cta_url.startsWith('/') ? '_self' : '_blank'}
                    >
                        {cta_label}{' '}
                        <span className="flex ml-2">
                            <IconArrowCaret color={'white'} size="sm" />
                        </span>
                    </a>
                </Link>
            )}
        </div>
    );
}

// Copied from the other interactives
export function Hotspot({ hotspot } : { hotspot: TypeHotspot; }) {
    const [active, setActive] = useState(false);
    const { isMobile, isMobileDesign } = useResponsive();

    const setIndex = () => {
        if (active) {
            return 40;
        } else {
            return 1;
        }
    };

    const styles = () => {
        return {
            top: `calc(${hotspot.y}% - 12px)`,
            left: `calc(${hotspot.x}% - 12px)`,
            zIndex: setIndex(),
        };
    };

    return (
        <div className="absolute hotspot" style={styles()}>
            <Tippy
                appendTo={(isMobile || isMobileDesign) ? () => document.body : 'parent'}
                content={<HotSpotInfobox hotspot={hotspot} />}
                interactive={true}
                placement={'bottom'}
                touch="hold"
                trigger="mouseenter focus click"
                onShow={(instance) => {
                    hideAll({ exclude: instance });
                    setActive(true);
                }}
                onHide={() => setActive(false)}
            >
                <div className="" tabIndex={0} aria-label={`Hotspot for ${hotspot.title}`}>
                    <div className="border rounded-full border-yellow">
                        <div className="w-[18px] rounded-full h-[18px] bg-yellow m-[3px]"></div>
                    </div>
                </div>
            </Tippy>
        </div>
    );
}

export default Hotspot;
