import { useEffect } from 'react';

export interface UseClearCacheProps {
    preview: boolean;
}

export function useClearCache(props: UseClearCacheProps) {
    const { preview } = props;
    useEffect(() => {
        if (!preview) return;
        window.workbox.messageSW({
            command: 'log',
            message: 'clear cache',
            action: 'CLEAR_CACHE',
        });
    }, []);
}

export default useClearCache;
