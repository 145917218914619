import { TypeRelatedResource } from '@moar/types';
import Link from 'next/link';
import { useIsOnline } from '../context/online';

/* eslint-disable-next-line */
export interface RelatedResourcesProps {
    relatedResources: TypeRelatedResource[];
}

export function RelatedResources(props: RelatedResourcesProps) {
    const { relatedResources } = props;
    const isOnline = useIsOnline();

    if (!isOnline || relatedResources.length === 0) return null;

    return (
        <div className="bg-blue print:hidden">
            <div className="grid grid-cols-12 px-6 lg:container md:px-10 lg:px-0">
                <div className="col-span-12 col-start-1 my-14 lg:col-span-10 lg:col-start-2">
                    <div className="mb-12 font-serif text-6xl text-white">Related Resources</div>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:gap-10 lg:grid-cols-4">
                        {relatedResources.map((resource) => (
                            <div className="mb-6 space-y-2">
                                <div>
                                    <div className="mb-4">
                                        <img alt="" src={resource.logo} className="h-[60px]" />
                                    </div>
                                </div>
                                <div className="font-serif text-lg text-white md:text-2xl">{resource.title}</div>
                                <p className="text-sm text-white md:text-base">{resource.description}</p>
                                <Link href={resource.url}>
                                    <a
                                        aria-label={`Learn more about ${resource.title}`}
                                        className="link"
                                        target={resource.url.startsWith('/') ? '_self' : '_blank'}
                                    >
                                        Learn More
                                    </a>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RelatedResources;
