import Panzoom, { PanzoomObject } from '@panzoom/panzoom'; //docs: https://github.com/timmywil/panzoom

const getOffset = function getOffset(node: HTMLElement | null) {
    // Doc: https://code.area17.com/a17/a17-helpers/wikis/getOffset

    if (node) {
        const rect = node.getBoundingClientRect();
        return {
            top: rect.top + (document.documentElement.scrollTop || document.body.scrollTop),
            left: rect.left + (document.documentElement.scrollLeft || document.body.scrollLeft),
            bottom: rect.bottom + (document.documentElement.scrollTop || document.body.scrollTop),
            right: rect.right + (document.documentElement.scrollLeft || document.body.scrollLeft),
            width: rect.width,
            height: rect.height,
        };
    } else {
        return null;
    }
};

const defaultConfig = {
    minScale: 1,
    startScale: 1,
    step: 0.3,
    pinchAndPan: true,
    //   contain: 'outside',
};

// Copied from interactives and converted for ts
// Some adjustments for adding a max scale, some variable renaming
class PanZoomController {
    control: PanzoomObject | null;
    panZoomNode: HTMLImageElement;
    largeZoomMode: boolean;
    parent: HTMLElement | null;

    constructor(panZoomElem: HTMLImageElement) {
        this.control = null;
        this.panZoomNode = panZoomElem;
        this.largeZoomMode = false;
        this.parent = this.panZoomNode.parentElement;
        this.load();
    }

    // used to keep hotspots the same size when zooming in or out
    _transformHotspots(scale: number) {
        if (!this.parent) return;
        // collect all info bubbles/hotspots within parent and scale them accordingly
        const hotspots = this.parent.querySelectorAll<HTMLElement>('.hotspot');
        const hotspotsArray = Array.from(hotspots);
        hotspotsArray.forEach((hotspot) => {
            hotspot.style.transform = `scale(${1 / scale})`;
        });
    }

    zoomIn() {
        return this.control?.zoomIn();
    }

    zoomOut() {
        return this.control?.zoomOut();
    }

    // center on a node in the panArea
    centerOn(node: HTMLElement) {
        const elem = node;
        const elemOffset = getOffset(elem);
        const parentOffset = getOffset(this.parent);
        if (this.parent === null || elemOffset === null || parentOffset === null) return;

        const relativePos = {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        };
        relativePos.top = elemOffset.top - parentOffset.top;
        relativePos.right = elemOffset.right - parentOffset.right;
        relativePos.bottom = elemOffset.bottom - parentOffset.bottom;
        relativePos.left = elemOffset.left - parentOffset.left;
        const resetX = -1 * relativePos.left;
        const resetY = -1 * relativePos.top;
        this.control?.pan(resetX, resetY, {
            relative: true,
        });
        this.control?.pan(this.parent.offsetWidth / 2, this.parent.offsetHeight / 2, {
            relative: true,
        });
    }

    setFreeZoom() {
        this.control?.setOptions({ contain: 'inside' });
        this.largeZoomMode = true;
    }

    initZoomChange() {
        // using any here because can't get right types for this custom event
        // it has detail
        this.panZoomNode.addEventListener('panzoomchange', (event: any) => {
            const currentScale = event.detail.scale;
            this._transformHotspots(currentScale);
            const changeEvent = new Event('panZoomUpdate');
            document.dispatchEvent(changeEvent);

            // const hotspots = [...this.parent.querySelectorAll('.hotspot')]

            // hotspots.forEach((elem) => {
            //   const offset = getOffset(elem)
            // })
        });
    }

    initZoomStart() {
        this.panZoomNode.addEventListener('panzoomstart', (event) => {
            // hideAll({ exclude: this.excludeRef })
            // if (this.largeZoomMode === false) {
            //     // the node you want to pan
            //     const elem = this.panZoomNode
            //     // the zoomable area (the parent of the zoomnode)
            //     const panArea = this.parent
            //     // this is not one size fits all and might not be your expected outcome
            //     // for this project if the image is ever too large for both its offset,
            //     // set to freezoom
            //     if (elem.offsetHeight < panArea.offsetHeight) {
            //         this.setFreeZoom()
            //     }
            //     if (elem.offsetWidth < panArea.offsetWidth) {
            //         this.setFreeZoom()
            //     }
            // }
        });
    }

    addNodeClasses() {
        const height = this.panZoomNode.offsetHeight;
        const width = this.panZoomNode.offsetWidth;
        const modalEl = document.getElementById('modal--zoom');
        if (!modalEl) return;

        const modalHeight = modalEl?.getBoundingClientRect().height;
        const modalWidth = modalEl?.getBoundingClientRect().width;
        // accounts for squarish images and images on mobile that were showing vertically
        const isHorizontalFullWidth = modalHeight > height && width === modalWidth;
        const isVertical = (height > width || modalHeight <= height) && !isHorizontalFullWidth;
        // console.log({ isHorizontalFullWidth, height, width, modalHeight, modalWidth });

        if (isVertical) {
            this.panZoomNode.classList.add(`h-full`);
            this.panZoomNode.classList.add(`max-w-none`);
        } else {
            this.panZoomNode.classList.add(`w-auto`);
            this.panZoomNode.classList.add(`max-w-screen`);
        }
    }

    centerPan(animate = false) {
        // account for padding, but not sure why the padding should be doubled?
        const modalEl = document.getElementById('modal--zoom');
        const modalHeight = modalEl?.getBoundingClientRect().height;
        const modalWidth = modalEl?.getBoundingClientRect().width;

        // this is actual image dimensions and not scaled.
        const imageNodeWidth = this.panZoomNode.getBoundingClientRect().width;
        const imageNodeHeight = this.panZoomNode.getBoundingClientRect().height;

        if (!modalWidth || !modalHeight) {
            return;
        }

        this.control?.pan((modalWidth - imageNodeWidth) / 2, (modalHeight - imageNodeHeight) / 2);
    }

    getMaxScale() {
        // 3263 x 2262
        // 1134 x 786
        const width = this.panZoomNode.naturalWidth;
        const height = this.panZoomNode.naturalHeight;
        const imageNodeWidth = this.panZoomNode.getBoundingClientRect().width;
        const imageNodeHeight = this.panZoomNode.getBoundingClientRect().height;
        let maxScale = 10;
        // horizontal
        if (width > height || height === width) {
            maxScale = width / imageNodeWidth;
        } else {
            maxScale = height / imageNodeHeight;
        }

        return maxScale;
    }

    load() {
        // const config = { ...defaultConfig, maxScale: this.getMaxScale() };
        const config = { ...defaultConfig };
        // the node you want to pan
        this.control = Panzoom(this.panZoomNode, config);

        this.initZoomChange();
        this.initZoomStart();
        this.addNodeClasses();

        // for debug purposes
        // window.panZoom = this.control;
        // window.panZoomClass = this;

        // setTimeout(() => {
        //     this.centerPan();
        // });
    }
}

export default PanZoomController;
