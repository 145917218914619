/* eslint-disable-next-line */
export interface IconPlusProps {}

export function IconPlus(props: IconPlusProps) {
    return (
        <span className="w-4 h-4">
            <svg aria-hidden={true} className="w-4 h-4" viewBox="0 0 18 18" fill="none">
                <path
                    d="M7 10C7.55228 10 8 10.4477 8 11V17C8 17.5523 8.44771 18 9 18C9.55229 18 10 17.5523 10 17V11C10 10.4477 10.4477 10 11 10H17C17.5523 10 18 9.55229 18 9C18 8.44771 17.5523 8 17 8H11C10.4477 8 10 7.55228 10 7V1C10 0.447715 9.55229 0 9 0C8.44771 0 8 0.447715 8 1V7C8 7.55228 7.55228 8 7 8H1C0.447715 8 0 8.44771 0 9C0 9.55229 0.447715 10 1 10H7Z"
                    className="fill-blue"
                ></path>
            </svg>
        </span>
    );
}

export default IconPlus;
