/* eslint-disable-next-line */
export interface IconShareLinkProps {}

export function IconShareLink(props: IconShareLinkProps) {
    return (
        <svg aria-hidden={true} className="w-[14px]" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.9955 7.005C10.3695 8.379 10.3695 10.606 8.9955 11.98L7.0055 13.97C5.6315 15.344 3.4045 15.344 2.0305 13.97C0.6565 12.596 0.6565 10.369 2.0305 8.995L3.7785 7.297"
                stroke="#254468"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.00511 8.99501C5.63111 7.62101 5.63111 5.39401 7.00511 4.02001L8.99511 2.03001C10.3691 0.656012 12.5961 0.656012 13.9701 2.03001C15.3441 3.40401 15.3441 5.63101 13.9701 7.00501L12.2221 8.70301"
                stroke="#254468"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default IconShareLink;
