import { createContext, ReactNode, useCallback, useContext } from 'react';
import usePersistedState from '../hooks/use-persisted-state/use-persisted-state';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ContextMyTimelineTitle = createContext<[string | undefined, (title: string) => void]>(['', () => {}]);

export function ProviderMyTimelineTitle({ children }: { children: ReactNode }) {
    const [title, setPersistedValue] = usePersistedState('my-timeline-title', '');
    return (
        <ContextMyTimelineTitle.Provider value={[title, setPersistedValue]}>{children}</ContextMyTimelineTitle.Provider>
    );
}

export function useContextMyTimelineTitle() {
    return useContext(ContextMyTimelineTitle);
}

export function useMyTimelineTitle() {
    const [title, setPersistedValue] = useContextMyTimelineTitle();
    const setTitle = useCallback((newTitle: string) => {
        setPersistedValue(newTitle);
    }, []);
    return [title, setTitle] as const;
}
