import { useIsOnline } from '../context/online';

/* eslint-disable-next-line */
export interface BannerOfflineProps {
    offlineAlertBanner: string;
}

export function BannerOffline(props: BannerOfflineProps) {
    const { offlineAlertBanner = 'Offline' } = props;
    const isOnline = useIsOnline();

    if (isOnline) return null;
    return (
        <div className="fixed z-40 bottom-[5.5rem] left-2 md:bottom-5 md:left-5 print:hidden">
            <div className="px-5 py-2 bg-black rounded-sm">
                <div
                    className="font-sans text-xs font-bold md:text-sm text-yellow"
                    dangerouslySetInnerHTML={{
                        __html: offlineAlertBanner,
                    }}
                ></div>
            </div>
        </div>
    );
}

export default BannerOffline;
