import { TypeTimelineObjectImage } from '@moar/types';
import { createContext, ReactNode, useContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ContextModalZoom = createContext<{
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    image: TypeTimelineObjectImage | null;
    setImage: (image: TypeTimelineObjectImage | null) => void;
}>({
    isOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIsOpen: () => {},
    image: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setImage: () => {},
});

export function ProviderModalZoom({ children }: { children: ReactNode }) {
    const [isOpen, setIsOpen] = useState(false);
    const [image, setImage] = useState<TypeTimelineObjectImage | null>(null);
    return (
        <ContextModalZoom.Provider value={{ isOpen, setIsOpen, image, setImage }}>{children}</ContextModalZoom.Provider>
    );
}

export function useContextModalZoom() {
    return useContext(ContextModalZoom);
}
