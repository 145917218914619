import { BannerNavMain } from '../banner-nav-main/banner-nav-main';
import Footer from '../footer/footer';
import { Transition } from '@headlessui/react';

import Layout from '../layout/layout';
import { NavFixedMobile } from '../nav-fixed-mobile/nav-fixed-mobile';
import NavMainFullDesktop from '../nav-main-full-desktop/nav-main-full-desktop';
import NavMainFullMobile from '../nav-main-full-mobile/nav-main-full-mobile';
import RelatedResources from '../related-resources/related-resources';
import { useEffect, useState } from 'react';
import { PageHead } from '../page-head/page-head';
import { TypePageHowToProps } from '@moar/types';
import BannerOffline from '../banner-offline/banner-offline';
import { useIsOnline } from '../context/online';
import { IconArrowCaret } from '@moar/svg';
import TextHtml from '../text-html/text-html';
import BlockVideo from '../block-video/block-video';
import { useIsPwa } from '../context/pwaContext';

export function PageHowTo(props: TypePageHowToProps) {
    const {
        page,
        timelineTitle,
        preview,
        sections,
        offlineAlertBanner,
        relatedResources,
        aboutPageTitle,
        glossaryPageTitle,
        glossaryItemsCount,
    } = props;
    const [isReady, setIsReady] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const isOnline = useIsOnline();
    const isPwa = useIsPwa();
    useEffect(() => {
        setIsReady(true);
    }, []);

    useEffect(() => {
        // Scroll to letter or word. Scroll didn't work on page load without this.
        const hash = window.location.hash;
        if (hash) {
            setTimeout(() => {
                document.querySelector(hash)?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }, []);

    const {
        howToPageTitle,
        howToSeoTitle,
        howToSeoImage,
        howToSeoDescription,
        howToVideo,
        howToCaptionFile,
        howToTranscript,
        howToTranscriptTitle,
        howToTranscriptDescription,
        howToDescription,
        howToDescriptionDesktop,
        howToDescriptionSafari,
        howToDescriptionAndroid,
    } = page;

    return (
        <Layout preview={preview}>
            <PageHead
                defaultTitle={timelineTitle}
                title={howToSeoTitle}
                image={howToSeoImage}
                description={howToSeoDescription}
            />
            <BannerOffline offlineAlertBanner={offlineAlertBanner} />
            <BannerNavMain preview={preview} title={timelineTitle} />
            <NavMainFullDesktop
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossaryItemsCount}
            />
            <NavMainFullMobile
                title={timelineTitle}
                sections={sections}
                aboutPageTitle={aboutPageTitle}
                howToPageTitle={howToPageTitle}
                glossaryPageTitle={glossaryPageTitle}
                glossaryItemsCount={glossaryItemsCount}
            />
            <Transition
                show={isReady}
                appear={true}
                enter={`transition-opacity duration-500`}
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave={`transition-opacity duration-500`}
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="bg-ivory min-h-page">
                    <div className="grid grid-cols-12 px-6 pt-24 pb-20 lg:container md:pt-28 md:px-10 lg:px-0">
                        <div className="col-span-12 lg:col-span-8 lg:col-start-3">
                            <div className="mb-8">
                                <h1 className="pb-4 mb-8 font-serif text-5xl border-b border-black md:text-7xl">
                                    {howToPageTitle}
                                </h1>

                                <div className="mt-6 ">
                                    {howToVideo && (
                                        <BlockVideo
                                            url={howToVideo}
                                            captionsUrl={howToCaptionFile}
                                            transcript={howToTranscript}
                                            transcriptTitle={howToTranscriptTitle}
                                            transcriptDescription={howToTranscriptDescription}
                                        />
                                    )}

                                    {howToDescription && (
                                        <div className="prose-page">
                                            <TextHtml html={howToDescription} />
                                        </div>
                                    )}

                                    {isPwa && (
                                        <>
                                            {howToDescriptionDesktop && (
                                                <div className="mt-16 prose-page scroll-mt-16" id="desktop">
                                                    <TextHtml html={howToDescriptionDesktop} />
                                                </div>
                                            )}
                                            {howToDescriptionSafari && (
                                                <div className="mt-16 prose-page scroll-mt-16" id="safari">
                                                    <TextHtml html={howToDescriptionSafari} />
                                                </div>
                                            )}
                                            {howToDescriptionAndroid && (
                                                <div className="mt-16 prose-page scroll-mt-16" id="android">
                                                    <TextHtml html={howToDescriptionAndroid} />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RelatedResources relatedResources={relatedResources} />
            </Transition>
            <NavFixedMobile />
            <Footer padForBottomNav={true} />
        </Layout>
    );
}

export default PageHowTo;
