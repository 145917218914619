import classNames from 'classnames';

/* eslint-disable-next-line */
export interface IconMagnifyingGlassProps {
    color?: 'white' | 'black' | 'light-blue' | 'yellow';
}

export function IconMagnifyingGlass(props: IconMagnifyingGlassProps) {
    const { color = 'black' } = props;

    const colorClass = classNames({
        'stroke-yellow group-hover:stroke-yellow': color === 'yellow',
        'stroke-white group-hover:stroke-yellow': color === 'white',
        'stroke-ash': color === 'black',
        'stroke-blue-300': color === 'light-blue',
    });
    return (
        <svg
            aria-hidden={true}
            className={`w-full h-full ${colorClass}`}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15.5 15.5L12.5 12.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M6.5 12.5C9.81371 12.5 12.5 9.81371 12.5 6.5C12.5 3.18629 9.81371 0.5 6.5 0.5C3.18629 0.5 0.5 3.18629 0.5 6.5C0.5 9.81371 3.18629 12.5 6.5 12.5Z"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default IconMagnifyingGlass;
