/* eslint-disable-next-line */
export interface IconGearProps {}

export function IconGear(props: IconGearProps) {
    return (
        <svg
            className="h-[12px] w-[12px]"
            aria-hidden={true}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#a)" stroke="#fff" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round">
                <path d="M8 10.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                <path d="M13.5 8c0-.465-.064-.913-.172-1.344l1.917-1.107-1.5-2.598-1.915 1.106A5.476 5.476 0 0 0 9.5 2.713V.5h-3v2.212a5.5 5.5 0 0 0-2.33 1.345L2.255 2.951l-1.5 2.598 1.917 1.107A5.512 5.512 0 0 0 2.5 8c0 .464.064.913.172 1.344L.755 10.451l1.5 2.598 1.916-1.106a5.476 5.476 0 0 0 2.33 1.344V15.5h3v-2.212a5.503 5.503 0 0 0 2.33-1.344l1.916 1.106 1.5-2.598-1.917-1.107c.106-.432.17-.881.17-1.345Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h16v16H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconGear;
