import { IconClose, IconICircle, IconZoom } from '@moar/svg';
import { useState, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useIsOnline } from '../context/online';

/* eslint-disable-next-line */
export interface CarouselLegendProps {
    creditText: string;
    mode?: 'dark' | 'light';
    accountForThumbs: boolean;
    clickZoom: () => void;
}

export function CarouselLegend(props: CarouselLegendProps) {
    const isOnline = useIsOnline();
    const { creditText, mode = 'intro', accountForThumbs = true, clickZoom } = props;
    const [isOpen, setIsOpen] = useState(false);

    const bgClasses = classNames({
        'bg-midnight': mode === 'light',
        'bg-yellow': mode === 'dark',
    });

    const bgClassesTrigger = classNames({
        'bg-midnight hover:bg-blue': mode === 'light',
        'bg-yellow hover:bg-yellow-dark': mode === 'dark',
    });

    const textColorCredit = classNames({
        'text-white': mode === 'light',
        'text-black': mode === 'dark',
    });

    const textColorTrigger = classNames({
        'text-yellow': mode === 'light',
        'text-midnight': mode === 'dark',
    });

    return (
        <>
            <Transition
                show={isOpen && !!creditText}
                as={Fragment}
                enter="z-[30] transform ease-out duration-300 transition"
                enterFrom="translate-y-4 opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className={`absolute z-[40] xl:w-3/4 pl-3 pr-10 pt-4 pb-3 ${textColorCredit} text-sm text-left leading-normal rounded-sm max-w-[93%] md:max-w-md lg:max-w-xl ${bgClasses} right-3 ${
                        accountForThumbs ? 'bottom-[13px] md:bottom-[20px]' : 'bottom-3'
                    }`}
                >
                    <button
                        onClick={() => setIsOpen(false)}
                        className="absolute top-0 right-0 flex items-center justify-center p-3 group"
                    >
                        <span className=" w-[12px] h-[12px]">
                            <IconClose color={mode === 'dark' ? 'black' : 'yellow'} />
                        </span>
                    </button>
                    {creditText}
                </div>
            </Transition>

            <div
                className={`absolute right-3 z-30 ${accountForThumbs ? 'bottom-[13px] md:bottom-[20px]' : 'bottom-3'}`}
            >
                <div className="flex space-x-3">
                    {creditText && (
                        <button
                            onClick={() => setIsOpen((isOpen) => !isOpen)}
                            className={`flex items-center justify-center p-2.5 text-sm font-bold rounded-sm md:px-3 h-[36px] ${textColorTrigger} ${bgClassesTrigger} group`}
                        >
                            <IconICircle color={mode === 'dark' ? 'midnight' : 'yellow'} />
                            <span className="hidden ml-2 xl:block" style={{ marginTop: '2px' }}>
                                Image Credit
                            </span>
                        </button>
                    )}

                    {isOnline && (
                        <button
                            onClick={() => clickZoom()}
                            className={`btn btn--primary p-0 flex justify-center items-center ml-3 h-[36px] w-[36px] ${
                                mode === 'dark' ? 'btn--primary' : 'btn--dark'
                            }`}
                        >
                            <IconZoom color={mode === 'dark' ? 'midnight' : 'yellow'} />
                            <span className="sr-only">Zoom</span>
                        </button>
                    )}
                </div>
            </div>
        </>
    );
}

export default CarouselLegend;
