import { useState, useCallback, useEffect } from 'react';
import { set, get } from 'idb-keyval';

export function usePersistedState<TState>(
    keyToPersistWith: string,
    defaultState: TState
): [TState | undefined, (newValue: TState) => void] {
    const [state, setState] = useState<TState | undefined>(undefined);

    // get initial state from storage
    useEffect(() => {
        console.log('get', defaultState, keyToPersistWith);

        get<TState>(keyToPersistWith).then((retrievedState) =>
            // If a value is retrieved then use it; otherwise default to defaultValue
            setState(retrievedState ?? defaultState)
        );
    }, []);

    const setPersistedValue = (newValue: TState) => {
        console.log('set new state');

        setState(newValue);
        set(keyToPersistWith, newValue);
    };

    return [state, setPersistedValue];
}

export default usePersistedState;
