import { TypeTimelineDate } from '@moar/types';
import classNames from 'classnames';
import { Paragraph } from '../paragraphs/paragraphs';
import { useInView } from 'react-cool-inview';
import useResponsive from '../hooks/use-responsive/use-responsive';
import TextHtml from '../text-html/text-html';

export interface ObjectDateProps {
    obj: TypeTimelineDate;
    color: string;
    mode?: 'light' | 'dark';
    isLast: boolean;
}

export function ObjectDate(props: ObjectDateProps) {
    const { isMobile } = useResponsive();
    const { observe, inView } = useInView({
        // fixes object not loading if page is scrolled and object is not fully on screen
        delay: 200,
        unobserveOnEnter: true,
        rootMargin: `${isMobile ? '-200px' : '0px'} 0px`,
    });
    const { obj, color, mode, isLast } = props;
    const { date, title, description, position } = obj;

    const textColor = classNames({
        'text-white': mode === 'dark',
        'text-black': mode === 'light',
    });

    const opacity = mode === 'dark' ? '0.15' : '0.4';

    return (
        <div
            id={obj.slug}
            className={`py-7 md:py-0 my-0 object-date ${
                position === 1 ? 'md:pt-[50px] md:mb-[50px]' : isLast ? 'md:pb-[50px]' : ' md:my-[50px]'
            } ${inView ? 'animate-fade-in' : 'opacity-0'}`}
            ref={observe}
        >
            <div className="md:mx-10 2xl:mx-auto 2xl:container">
                <div className="grid grid-cols-12 gap-0 md:gap-10">
                    <div className="col-span-12 col-start-1 lg:col-span-10 lg:col-start-3">
                        <div
                            className="flex flex-col px-5 py-6 mx-5 max-w-7xl md:px-12 md:py-8 md:gap-10 md:flex-none md:grid md:mx-auto md:grid-cols-8 lg:grid-cols-10 lg:min-h-date"
                            style={{ backgroundColor: color.replace(', 1)', `, ${opacity})`) }}
                        >
                            <div className="flex items-center justify-start md:col-span-4 lg:col-span-5">
                                <h3 className={`text-4xl font-serif md:text-8xl mb-1 md:mb-0 ${textColor}`}>{date}</h3>
                            </div>
                            <div
                                className={`flex flex-col justify-center px-0 md:pl-5 lg:px-0 md:col-span-4 lg:col-span-5`}
                            >
                                <div className="space-y-1">
                                    {!!title && (
                                        <h4 className={`text-xl font-serif md:text-5xl ${textColor}`}>{title}</h4>
                                    )}

                                    <TextHtml
                                        mode={mode}
                                        className={`pt-1 ${textColor} mb-3 text-base font-sans font-normal`}
                                        html={description}
                                        sectionSlug={obj.slug}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ObjectDate;
