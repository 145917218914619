import classNames from 'classnames';

export interface HeadersProps {
    Tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
    mode?: 'light' | 'dark';
    textColor?: string;
    children: string | JSX.Element | JSX.Element[];
    className?: string;
}
export interface HeaderProps {
    Tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
    mode: 'light' | 'dark';
    headerClass: 'hxl' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    textColor?: string;
    children: string | JSX.Element | JSX.Element[];
    className?: string;
}

function Header(props: HeaderProps) {
    const { Tag, mode = 'light', headerClass, children, className, textColor } = props;
    const defaultTextColor = classNames({
        'text-white': mode === 'dark',
        'text-black': mode === 'light',
    });

    return <Tag className={`${textColor ? textColor : defaultTextColor} ${className}`}>{children}</Tag>;
}

export function Hxl(props: HeadersProps) {
    const { Tag = 'h1', mode = 'light', textColor, className = '', children } = props;
    return (
        <Header
            Tag={Tag}
            mode={mode}
            textColor={textColor}
            headerClass="hxl"
            className={`text-7xl font-serif md:text-8xl transition-all ${className}`}
        >
            {children}
        </Header>
    );
}

export function H1(props: HeadersProps) {
    const { Tag = 'h1', mode = 'light', textColor, className = '', children } = props;
    return (
        <Header
            Tag={Tag}
            mode={mode}
            textColor={textColor}
            headerClass="h1"
            className={`text-5xl font-serif md:text-7xl transition-all ${className}`}
        >
            {children}
        </Header>
    );
}

export function H2(props: HeadersProps) {
    const { Tag = 'h2', mode = 'light', textColor, className = '', children } = props;
    return (
        <Header
            Tag={Tag}
            mode={mode}
            textColor={textColor}
            headerClass="h2"
            className={`text-3xl font-serif md:text-6xl transition-all ${className}`}
        >
            {children}
        </Header>
    );
}

export function H3(props: HeadersProps) {
    const { Tag = 'h3', mode = 'light', textColor, className = '', children } = props;
    return (
        <Header
            Tag={Tag}
            mode={mode}
            textColor={textColor}
            headerClass="h3"
            className={`font-serif text-xl md:text-4xl transition-all ${className}`}
        >
            {children}
        </Header>
    );
}

export function H4(props: HeadersProps) {
    const { Tag = 'h4', mode = 'light', textColor, className = '', children } = props;
    return (
        <Header
            Tag={Tag}
            mode={mode}
            textColor={textColor}
            headerClass="h4"
            className={`font-serif text-lg md:text-2xl transition-all ${className}`}
        >
            {children}
        </Header>
    );
}

export function H5(props: HeadersProps) {
    const { Tag = 'h5', mode = 'light', textColor, className = '', children } = props;
    return (
        <Header
            Tag={Tag}
            mode={mode}
            textColor={textColor}
            headerClass="h5"
            className={`font-sans text-base font-normal md:text-base-plus transition-all ${className}`}
        >
            {children}
        </Header>
    );
}
